import React, {useState, useEffect} from 'react';
import labels from '../../config/localization';
import PageTitle from '../PageTitle';
import constants from '../../config/constants';
import FormControl from '../FormControls/FormControl';
import './signup.scss';
import Notification from '../Notification';
import countries from 'country-list';
import Loader from '../Loader';
import userIcon from '../../assets/images/user_avtar.png';
import SignupPaymentGateway from '../SignupPaymentGateway';
import { Link } from 'react-router-dom';
import { setDate } from 'date-fns';
import physioAgeTestPanels from '../../assets/sample pdfs/physioAge_test_panels.pdf'
import moment from 'moment';

const stylesClasses = {
    labelClass: 'col-lg-2',
    inputClass: 'col-lg-10'
}
const dropdownClasses = {
    valueKey: 'code',
    textKey: 'value',
    labelClass: 'col-lg-2',
    inputClass: 'col-lg-10'
}
const openTestPanelList = (filePath) => {
    var link = document.createElement('a');
    link.href = filePath;
    link.target = "_blank";
    link.click();
}

// const ToggleSwitch = ({props}) => {
//     const [isToggled, setIsToggled] = useState(true);
//     const [toggleLabel, setToggleLabel] = useState('MFA is required for everyone');

//     useEffect(() => {
//         setIsToggled(props.currentSignUpSite && props.currentSignUpSite.mfaRequired)
//         return () => {
//             // componentWillUnmount() //
//         }
//     }, [props.currentSignUpSite.mfaRequired]);

//     const handleToggle = () => {
//       setIsToggled(!isToggled);
//       setToggleLabel(!isToggled ? "MFA is required for everyone" : "MFA is optional for everyone");
//       props.editSignUpSite({target: {id: "mfaRequired", value: !isToggled ? 1 : 0 }})
//     };
  
//     return (
//       <div className="toggle-switch">
//         <div className={`switch ${isToggled ? 'toggled' : ''}`} onClick={handleToggle}>
//           <div className="switch-handle" />
//         </div>{toggleLabel && <span className='pl-2 help-block'>{toggleLabel}</span>}
//       </div>
//     );
// };

class SignUp extends React.Component {

    constructor(props) {
        super(props);
        this.state = { showOthers: 0,  selectedNoumenonCodesOptions: [] };
        this.toggleToShowOthers = this.toggleToShowOthers.bind(this);
       
    }
    componentDidMount() {
        this.props.toggleNotification(false);
        this.props.setPageTitle(labels.signup_title);
        this.props.setBreadCrumbs([
            { text: labels.physioage_label, path: '/patients/list' },
            { text: labels.signup_title, path: '' }
        ])
        this.props.setCurrentSignUpUser();
        this.props.setCurrentSignUpSite();
        this.props.GetAllNoumenonNames();
        document.title = `${labels.physioage_label}: ${labels.signup_title}`;

        if (this.signUpForm && this.signUpForm.inputEntry) {
            this.signUpForm.inputEntry.focus()
        }
        this.props.setNoumenonFields();
    }

    getDefaultOrders(defaultorders) {
        //return defaultorders ? defaultorders.split(',').map(a => ({ name: constants.map_defaultpanels_names_to_values_reverse[a]?constants.map_defaultpanels_names_to_values_reverse[a]:a })) : []
        return defaultorders ? defaultorders.split(',').map(a => ({ name: Object.keys(constants.map_defaultpanels_names_to_values).find(key => constants.map_defaultpanels_names_to_values[key] == a) ? Object.keys(constants.map_defaultpanels_names_to_values).find(key => constants.map_defaultpanels_names_to_values[key] == a) : a })) : []
    }
    getDefaultNoumena(NoumenonCodes){
        return NoumenonCodes ? NoumenonCodes.split(',').map(a => ({ code: Object.keys(this.props.allNoumenonNameList).find(key => this.props.allNoumenonNameList[key] == a) ? Object.keys(this.props.allNoumenonNameList).find(key => this.props.allNoumenonNameList[key] == a) : a })) : []
    }

    resetPassword(e) {
        if (this.props.currentSignUpUser && e) {
            e.preventDefault()
            this.props.resetPassword(this.props.history, constants.edit)
        }
    }

    toggleToShowOthers(value) {
        if (value == "others") {
            this.setState((state, props) => ({
                showOthers: 1
            }));
        }
        else {
            this.setState((state, props) => ({
                showOthers: 0
            }));
        }
    }

    playOnbordingVideo(){
        window.open('/onbording_video', '_blank');
    }

    render() {
        const { selectedNoumenonCodesOptions } = this.state;
        const selectedCodes = this.props.CreateNoumenonList ? this.props.CreateNoumenonList.map(noumenon => noumenon.code) : []
        const options = this.props.allNoumenonNameList ? this.props.allNoumenonNameList.map(noumenon => ({
            ...noumenon,
            isDisabled: selectedCodes.includes(noumenon.code),
          }))
         : []
        return (
            <React.Fragment>
                {/* <div className='row col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-5 page-title-container'>
                    <div className='col-lg-3 col-1'></div>
                    <div className={`page-title-wrap col-lg-9 col-11 no-breadcrumb-header`}>
                        <PageTitle pageTitle={this.props.pageTitle} breadCrumbs={this.props.breadCrumbs}
                            subTitle={this.props.subTitle}
                            history={this.props.history}
                            navigatePatients={this.props.navigatePatients}
                            {...this.props} />
                    </div>
                </div> */}
                <div className='col-lg-12 row d-flex flex-lg-row flex-column-reverse mt-5'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 data-section p-0 signupchildren'>
                            {this.props.toggleSignupRender == 1 ?
                                <>
                                    <div className='col-lg-2'></div>
                                    <div className={`col-lg-9 no-breadcrumb-header`}>
                                        <PageTitle pageTitle={this.props.pageTitle} breadCrumbs={this.props.breadCrumbs}
                                            subTitle={this.props.subTitle}
                                            history={this.props.history}
                                            navigatePatients={this.props.navigatePatients}
                                            {...this.props} />

                                        <div className="signup-form-info alert alert-info mt-4">
                                            <p>
                                            <div dangerouslySetInnerHTML={{ __html: labels.messages.signup_intro_text_1}}></div>
                                            </p>
                                            <p>
                                            <div dangerouslySetInnerHTML={{ __html: labels.messages.signup_intro_text_2}}></div>
                                            </p><br/>
                                            <p className='onborading-video'>Video: <a><b onClick={(e) =>{this.playOnbordingVideo()}}>{labels.messages.signun_intro_text_4}</b></a></p> <br />
                                            <p>{labels.messages.signup_intro_text_3}</p>
                                        </div>
                                    </div>
                                </> : <>
                                    <div className='col-lg-3'></div>
                                    <div className={`col-lg-9 no-breadcrumb-header`}>
                                        <PageTitle pageTitle={this.props.pageTitle} breadCrumbs={this.props.breadCrumbs}
                                            subTitle={this.props.subTitle}
                                            history={this.props.history}
                                            navigatePatients={this.props.navigatePatients}
                                            {...this.props} />

                                        <div className="signup-form-info alert alert-info mt-4">
                                            <p>{labels.sites_labels.payment_gateway_info}</p>
                                        </div>
                                    </div>
                                </>}
                        </div>
                    </div>
                </div>
                {/* {this.props.toggleSignupRender == 1 ?
                    // Intro Text
                    <div className='col-lg-12 row mt-4'>
                        <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 signup-intro'>
                            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 data-section ml-4 signupchildren'>
                                <div className='col-lg-3'></div>
                                <div className="col-lg-9 signup-form-info alert alert-info">
                                    <p>{labels.messages.signup_intro_text_1}</p><br />
                                    <p>{labels.messages.signup_intro_text_2}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className='col-lg-12 row mt-4'>
                        <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 signup-intro'>
                            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 data-section ml-4 signupchildren'>
                                <div className='col-lg-3'></div>
                                <div className="col-lg-9 signup-form-info alert alert-info">
                                    <p>{labels.sites_labels.payment_gateway_info}</p>
                                </div>
                            </div>
                        </div>
                    </div>} */}
                {this.props.toggleSignupRender == 1 ?
                    <div className='col-lg-12 row d-flex flex-lg-row flex-column-reverse mb-5'>

                        <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12'>
                            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 data-section p-0 signupchildren'>

                                <div className='col-lg-3'></div>
                                <form className='signup-form container col-lg-9 mt-0 mb-5 p-0' onSubmit={(e) => {
                                    e.preventDefault()
                                    // if (!this.props.currentSignUpUser.id) 
                                    let signupParams = this.props.saveSignUpUser(this.props.history);
                                    if (signupParams) {
                                        this.props.toggleSignupRendering();
                                    }
                                }}>
                                    {this.props.isLoading ? <Loader /> : null}

                                    {/* User */} 
                                    <h3 className='ml-3 mt-4 mb-5'>Primary Contact Details</h3>
                                    <FormControl onChange={this.props.editSignUpUser} required={true} id='role'
                                        label={labels.user_labels.role} type='select' labelClass='col-lg-2'
                                        inputClass='col-lg-10'
                                        options={constants.roles.caregiver_roles}
                                        textKey={'text'} valueKey={'value'}
                                        help={labels.messages.provider_and_caregiver_message}
                                        value={this.props.currentSignUpUser && this.props.currentSignUpUser.isProvider != 0 ? 'provider' : 'caregiver'}
                                    />
                                    {/* <FormControl ref={(element) => this.signUpForm = element} type='select' id='honorificPrefix' onChange={this.props.editSignUpUser} value={this.props.currentSignUpUser.honorificPrefix ? this.props.currentSignUpUser.honorificPrefix : ''}
                                        options={constants.honorific_prefix} label={labels.provider_labels.honorific_prefix} textKey={'value'} valueKey={'value'} labelClass='col-lg-2' inputClass='col-lg-10' /> */}
                                    <FormControl required={true} onChange={this.props.editSignUpUser} value={this.props.currentSignUpUser.firstName ? this.props.currentSignUpUser.firstName : ''} id='firstName' label={labels.user_labels.firstname} type='text' labelClass='col-lg-2' inputClass='col-lg-10' />
                                    <FormControl required={true} onChange={this.props.editSignUpUser} value={this.props.currentSignUpUser.lastName ? this.props.currentSignUpUser.lastName : ''} id='lastName' label={labels.user_labels.lastname} type='text' labelClass='col-lg-2' inputClass='col-lg-10' />
                                    {this.props.currentSignUpUser.isProvider ? <FormControl type='text' id='honorificSuffix' label={labels.provider_labels.honorific_suffix} onChange={this.props.editSignUpUser} value={this.props.currentSignUpUser.honorificSuffix ? this.props.currentSignUpUser.honorificSuffix : ''}
                                        help={labels.provider_labels.honorific_suffix_help} labelClass='col-lg-2' inputClass='col-lg-10' /> : ''}
                                    <FormControl required={true} onChange={this.props.editSignUpUser} value={this.props.currentSignUpUser.email ? this.props.currentSignUpUser.email : ''} id='email' validate label={labels.user_labels.email} type='email' labelClass='col-lg-2' inputClass='col-lg-10' />

                                    {/* <FormControl required={true}
                                        onChange={this.props.editSignUpPassword} id='password'
                                        autocomplete="new-password"
                                        label={labels.user_labels.password}
                                        type='password' labelClass='col-lg-2' inputClass='col-lg-10'
                                        onKeyDown={(e) => e.keyCode == constants.enter_key_code ? this.resetSignUpPassword(e) : void (0)} />

                                    <FormControl required={true}
                                        onChange={this.props.editSignUpPassword}
                                        id='confirmPassword'
                                        label={labels.user_labels.retype_password}
                                        help={!this.props.isPasswordMatched ? labels.user_labels.password_not_matched : ''}
                                        type='password' labelClass='col-lg-2'
                                        inputClass={`col-lg-10 ${!this.props.isPasswordMatched ? constants.notification_type.error : ''}`}
                                        onKeyDown={(e) => e.keyCode == constants.enter_key_code ? this.resetSignUpPassword(e) : void (0)} /> */}
                                    
                                    {/* Plan Details */}
                                    <h3 className='ml-3 mt-4 mb-5'>Plan Details</h3>
                                    <FormControl onChange={this.props.editSignUpSite} required={true} id='planType'
                                        label={labels.user_labels.choose_plan} type='select' 
                                        labelClass='col-lg-2'
                                        inputClass='col-lg-10'
                                        options={constants.plans}
                                        textKey={'text'} valueKey={'value'}
                                        help={<div dangerouslySetInnerHTML={{ __html: labels.messages.plan_message}}></div>}
                                        value={this.props.currentSignUpSite && this.props.currentSignUpSite.planType ? this.props.currentSignUpSite.planType  : ''}
                                    />

                                    {/* Site */}
                                    <h3 className='ml-3 my-5'>Site Details</h3>
                                    <FormControl value={this.props.currentSignUpSite.name ? this.props.currentSignUpSite.name : ''} onChange={this.props.editSignUpSite} type='text' id='name' required={true} {...stylesClasses} label={labels.sites_labels.name_label} help={labels.sites_labels.name_help} />
                                    <FormControl onChange={this.props.editSignUpSite} value={this.props.currentSignUpSite.siteEmail ? this.props.currentSignUpSite.siteEmail : ''} id='siteEmail' validate label={labels.sites_labels.email} type='email' {...stylesClasses} help={labels.sites_labels.email_help} />
                                    {/* { this.props.currentSignUpSite && process.env.REACT_APP_MFA_ENABLED != 'false' ?
                                        <div className='d-flex pt-3 pb-3'>
                                            <label className='col-2'>Multi-Factor authentication (MFA)</label>
                                            <div className='col-5 pt-2 p-0'>
                                                <ToggleSwitch props={this.props}/>
                                            </div>
                                        </div>
                                    : null } */}
                                    {/* Site Specific GPA - PMI-3281 */}
                                    {/* <legend className='m-0 border-0'>{labels.sites_labels.gpa_noumena}</legend>    
                                    { this.props.CreateNoumenonList ?
                                        <div className='bootstrap-table-container createMultipleDocument'>
                                            <table className='react-bootstrap-table table'>
                                                <thead>  
                                                <tr>
                                                    <th className='col-6' >{labels.sites_labels.noumena}</th>
                                                    <th className='col-3'>{labels.sites_labels.start_date}</th>
                                                    <th className='col-3'>{labels.sites_labels.end_date}</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                    this.props.CreateNoumenonList.map((element, index) => (  
                                                        <tr key={element.uuid}>
                                                            <td className='col-6'>
                                                                <FormControl
                                                                    value={element.code}
                                                                    onChange={ (e) => this.props.editNoumenon(index, e, element.uuid)} type='select' id='code'
                                                                    getOptionLabel={opt => opt.noumenonName}
                                                                    getOptionValue={opt => opt.code}
                                                                    textKey={'noumenonName'} valueKey={'code'}
                                                                    options={options} className='form-control'
                                                                    isOptionDisabled={(option) => option.isDisabled}
                                                                />
                                                            </td>
                                                            <td className='col-3'>
                                                                <FormControl type='date' id='startDate' onChange={ (e) => this.props.editNoumenon(index, {target: {id: 'startDate', value: e}}, element.uuid)} 
                                                                    selected={element && element.startDate ? new Date(element.startDate) : ''}
                                                                    disabledKeyboardNavigation
                                                                    popperPlacement= {'bottom-end'}
                                                                    maxDate={  
                                                                        element.endDate ?
                                                                        moment(element.endDate).add(-1, 'days').toDate() : ""}
                                                                   
                                                                    
                                                                />
                                                            </td>
                                                            <td className='col-3'>
                                                            <FormControl type='date' id='endDate' onChange={ (e) => this.props.editNoumenon(index, {target: {id: 'endDate', value: e}}, element.uuid)} 
                                                                selected={element && element.endDate ? new Date(element.endDate) : ''}
                                                                disabledKeyboardNavigation
                                                                popperPlacement= {'bottom-end'}
                                                                minDate={ element.startDate  ?
                                                                    moment(element.startDate ).add(1, 'days').toDate() : ""} 
                                                                
                                                            />
                                                            </td>
                                                            <td>
                                                            <i className="fa fa-trash status-indicator text-danger trash-icon" onClick={() =>this.props.removeNoumenonFields(element.uuid)}> </i> 
                                                            </td>
                                                        </tr>
                                                        ))}
                                                        <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            <i className="fa fa-plus status-indicator text-success trash-icon" onClick={() => this.props.addNoumenonFields()}> </i>
                                                        </td>
                                                        </tr>
                                                    
                                                </tbody> 
                                            </table>
                                        </div>
                                    : null } */}
                                    {/* <FormControl value={this.props.currentSignUpSite.category ? this.props.currentSignUpSite.category : ''} onChange={this.props.editSignUpSite} required={true} type='select' id='category' {...dropdownClasses} label={labels.sites_labels.category_label} options={constants.site_categories} /> */}
                                    {/* <legend>{labels.sites_labels.features_label}</legend>
                                <FormControl checked={this.props.currentSignUpSite.acceptsReferrals && parseInt(this.props.currentSignUpSite.acceptsReferrals) ? true : false} onChange={this.props.editSignUpSite} type='checkbox' id='acceptsReferrals' label={labels.sites_labels.site_referrals_help} labelClass='col-lg-10' />
                                <FormControl checked={this.props.currentSignUpSite.enableDocuments} type='checkbox' id='enableDocuments' onChange={this.props.editSignUpSite} label="Enable 'Documents' Feature" labelClass='col-lg-10' />
                                <FormControl checked={this.props.currentSignUpSite.wantsWholePunchMargin && parseInt(this.props.currentSignUpSite.wantsWholePunchMargin) ? true : false} onChange={this.props.editSignUpSite} type='checkbox' id='wantsWholePunchMargin' label={labels.sites_labels.margins_help} labelClass='col-lg-10' />
                                <FormControl checked={this.props.currentSignUpSite.acceptsMessages} onChange={this.props.editSignUpSite} type='checkbox' id='acceptsMessages' label={labels.sites_labels.sites_message_acknowledge} labelClass='col-lg-10' />
                                <FormControl checked={this.props.currentSignUpSite.includePhoneInSummary} onChange={this.props.editSignUpSite} type='checkbox' id='includePhoneInSummary' label={labels.sites_labels.include_phone_in_summary} labelClass='col-lg-10' /> */}
                                    <legend>{labels.sites_labels.preferences}</legend>
                                    <FormControl value={this.props.currentSignUpSite.languagePreference}
                                        onChange={this.props.editSignUpSite} type='select' id='languagePreference' required={true}
                                        {...dropdownClasses} label={labels.sites_labels.language_preference} options={constants.language_preference} />
                                    <FormControl value={this.props.currentSignUpSite.datePreference}
                                        onChange={this.props.editSignUpSite} type='select' id='datePreference'
                                        {...dropdownClasses} label={labels.sites_labels.date_preference} help={labels.sites_labels.date_preference_help_tip} options={constants.date_preference} />
                                    <FormControl value={this.props.currentSignUpSite.unitPreference ? this.props.currentSignUpSite.unitPreference : ''}
                                        onChange={this.props.editSignUpSite} type='select' id='unitPreference' required={true}
                                        {...dropdownClasses} label={labels.sites_labels.unit_preference} help={labels.sites_labels.unit_preference_help_tip} options={constants.unit_preference} />
                                    <FormControl onChange={this.props.logoUploadedInSignUp} type='file' id='logo' label={labels.sites_labels.logo_label} help={labels.sites_labels.logo_help_tip} {...stylesClasses} ref={r => this.changeLogoValue = r} />
                                    {this.props.currentSignUpSite.logoFile ?
                                        <React.Fragment>
                                            <label>{labels.sites_labels.current_image_label}</label>
                                            <img className='site-logo-preview' src={`data:${this.props.currentSignUpSite.logoContentType};base64,${this.props.currentSignUpSite.logoFile}`} />
                                            {/* <i className='fa fa-times-circle danger' title='Remove picture' onClick={(e) => this.props.removePic(e, this.changeLogoValue, 'logo')}>{labels.provider_labels.remove_pic}</i> */}
                                            <button className="btn btn-danger" onClick={(e) => this.props.removeLogoPicInSignUp(e, this.changeLogoValue, 'logo')}>Remove</button>
                                        </React.Fragment> : null
                                    }

                                    <h3 className='mt-5 mb-5'>Data Sources</h3>
                                    <div className='notification alert alert-info'>
                                        <p>{labels.sites_labels.default_panels_tip} Click <a onClick={() => {openTestPanelList(physioAgeTestPanels) }}>here</a> for a complete list of panels</p>
                                    </div>
                                    {/* <Notification notificationType='info' texts={[labels.sites_labels.default_panels_tip]} /> */}
                                    <legend>{labels.sites_labels.blood_and_urine}</legend>
                                    <FormControl value={this.getDefaultOrders(this.props.currentSignUpSite.bloodAndUrinePanels)}
                                        onChange={this.props.editBloodAndUrinePanels} type='select' id='bloodAndUrinePanels' isMulti={true}
                                        getOptionLabel={opt => opt.name}
                                        getOptionValue={opt => opt.name}
                                        label={labels.sites_labels.default_panels}
                                        options={constants.blood_and_urine_panels.sort((a, b) => a.name >= b.name ? 1 : -1)} labelClass='col-lg-2' className='col-lg-8 p-0' />
                                    <legend>{labels.sites_labels.optional_biomarkers}</legend>
                                    <FormControl value={this.getDefaultOrders(this.props.currentSignUpSite.optionalBiomarkerPanels)}
                                        onChange={this.props.editOptionalBiomarkerPanels} type='select' id='optionalBiomarkerPanels' isMulti={true}
                                        getOptionLabel={opt => opt.name}
                                        getOptionValue={opt => opt.name}
                                        label={labels.sites_labels.default_panels}
                                        options={constants.optional_biomarker_panels.sort((a, b) => a.name >= b.name ? 1 : -1)} labelClass='col-lg-2' className='col-lg-8 p-0' />
                                    <legend>{labels.sites_labels.electronic_lab_interfaces}</legend>
                                    <Notification notificationType='info' texts={[labels.sites_labels.electronic_lab_interfaces_help]} />
                                    <FormControl value={this.props.currentSignUpSite.questAccNo ? this.props.currentSignUpSite.questAccNo : ''}
                                        onChange={this.props.editSignUpSite} type='text' id='questAccNo' label={labels.sites_labels.quest_account_number} labelClass='col-lg-2' inputClass='col-lg-10' />
                                    <FormControl value={this.props.currentSignUpSite.labcorpAccNo ? this.props.currentSignUpSite.labcorpAccNo : ''}
                                        onChange={this.props.editSignUpSite} type='text' id='labcorpAccNo' label={labels.sites_labels.labcorp_account_number} labelClass='col-lg-2' inputClass='col-lg-10' />


                                    <legend>{labels.sites_labels.referral_info}</legend>
                                    <Notification notificationType='info' texts={[labels.sites_labels.address_help]} />
                                    <FormControl required={this.props.currentSignUpSite.acceptsReferrals ? true : false} value={this.props.currentSignUpSite.address ? this.props.currentSignUpSite.address : ''} onChange={this.props.editSignUpSite} type='text' id='address' {...stylesClasses} label={labels.sites_labels.address_label} />
                                    <FormControl required={this.props.currentSignUpSite.acceptsReferrals ? true : false} value={this.props.currentSignUpSite.city ? this.props.currentSignUpSite.city : ''} onChange={this.props.editSignUpSite} type='text' id='city' {...stylesClasses} label={labels.sites_labels.city_label} />
                                    <FormControl required={this.props.currentSignUpSite.acceptsReferrals ? true : false} value={this.props.currentSignUpSite.state ? this.props.currentSignUpSite.state : ''} onChange={this.props.editSignUpSite} type='text' id='state' {...stylesClasses} label={labels.sites_labels.state_label} />
                                    <FormControl required={this.props.currentSignUpSite.acceptsReferrals ? true : false} value={this.props.currentSignUpSite.postalCode ? this.props.currentSignUpSite.postalCode : ''} onChange={this.props.editSignUpSite} type='text' id='postalCode' {...stylesClasses} label={labels.sites_labels.postal_code} />

                                    <FormControl value={this.props.currentSignUpSite.country ? this.props.currentSignUpSite.country : ''}
                                        onChange={this.props.editSignUpSite} type='select' id='country' {...stylesClasses} label={labels.sites_labels.country} textKey='name' valueKey='code' options={countries.getData()} />
                                    <FormControl value={this.props.currentSignUpSite.phone ? this.props.currentSignUpSite.phone : ''}
                                        onChange={this.props.editSignUpSite} type='text' id='phone' required={true}
                                        {...stylesClasses} label={labels.sites_labels.phone} help={labels.sites_labels.phone_help} />
                                    <FormControl value={this.props.currentSignUpSite.website ? this.props.currentSignUpSite.website : ''}
                                        onChange={this.props.editSignUpSite} id='website' {...stylesClasses} label={labels.sites_labels.website_label} help={labels.sites_labels.website_help} />

                                    <legend>{labels.sites_labels.other_info}</legend>
                                    <FormControl value={this.props.currentSignUpSite.emrDetails ? this.props.currentSignUpSite.emrDetails : ''}
                                        onChange={this.props.editSignUpSite} type='text' id='emrDetails'
                                        {...stylesClasses} label={labels.sites_labels.what_emr_using} />
                
                                    <p className="addMargin">{labels.user_labels.user_survey_label}</p>
                                    <FormControl checked={this.props.currentSignUpUser.userSurvey == 'Conference' ? true : false} id="conference" name="userSurvey" value="Conference" onClick={() => (this.toggleToShowOthers("conference"))} onChange={this.props.editSignUpUser} label={labels.user_labels.conference_label} type="radio" />
                                    <FormControl checked={this.props.currentSignUpUser.userSurvey == 'Newsletter' ? true : false} id="newsLetter" name="userSurvey" value="Newsletter" onClick={() => (this.toggleToShowOthers("newsLetter"))} onChange={this.props.editSignUpUser} label={labels.user_labels.newsletter_label} type="radio" />
                                    <FormControl checked={this.props.currentSignUpUser.userSurvey == 'Internet Search' ? true : false} id="internetSearch" name="userSurvey" value="Internet Search" onClick={() => (this.toggleToShowOthers("internetSearch"))} onChange={this.props.editSignUpUser} label={labels.user_labels.internet_search_label} type="radio" />
                                    <FormControl checked={this.props.currentSignUpUser.userSurvey == 'Magazine' ? true : false} id="magazine" name="userSurvey" value="Magazine" onClick={() => (this.toggleToShowOthers("magazine"))} onChange={this.props.editSignUpUser} label={labels.user_labels.magazine_label} type="radio" />
                                    <FormControl checked={this.props.currentSignUpUser.userSurvey == 'Television' ? true : false} id="television" name="userSurvey" value="Television" onClick={() => (this.toggleToShowOthers("television"))} onChange={this.props.editSignUpUser} label={labels.user_labels.television_label} type="radio" />
                                    <FormControl checked={this.props.currentSignUpUser.userSurvey == 'Friend' ? true : false} id="friend" name="userSurvey" value="Friend" onClick={() => (this.toggleToShowOthers("friend"))} onChange={this.props.editSignUpUser} label={labels.user_labels.friend_label} type="radio" />
                                    <FormControl checked={this.state.showOthers == 1 ? true : false} id="others" name="userSurvey" value="" onClick={() => (this.toggleToShowOthers("others"))} onChange={this.props.editSignUpUser} label={labels.user_labels.others_label} labelClass='col-lg-2' type="radio" />
                                    {this.state.showOthers == 1 ? <FormControl name="userSurvey" value={this.props.currentSignUpUser.userSurvey} onChange={this.props.editSignUpUser} inputClass='col-lg-10 mt-0 setTextFieldMargin' type="text" /> : null}

                                    <p className="addMargin mt-5 mb-0">{labels.user_labels.signup_comments}</p>
                                    <FormControl type="textarea" value={this.props.currentSignUpUser.signupComments} id="signupComments" onChange={this.props.editSignUpUser} labelClass='col-lg-2' inputClass='col-lg-10' signupInput />

                                    <div className="d-flex flex-row termsAndConditionsStyles mt-5 mb-4">
                                        {/* <FormControl onChange={this.props.editSignUpSite} type='checkbox' id='acceptTermsAndConditions' /> */}
                                        <div>
                                            <input
                                                checked={this.props.currentSignUpSite.acceptTermsAndConditions == 1 ? true : false}
                                                onChange={this.props.editSignUpSite}
                                                id='acceptTermsAndConditions'
                                                type='checkbox'
                                            />
                                        </div>
                                        <label className="termsAndConditionsLabel" for="acceptTermsAndConditions">I accept the <Link to='termsandconditions' target="_blank">{labels.terms_and_conditions}</Link></label>

                                    </div>


                                    <div className='col-lg-7 d-flex justify-center update-password'>
                                        <FormControl text={labels.next_label} type='button' btnType={constants.notification_type.success} className="showToolTip" />
                                        {/* <FormControl disabled={this.props.currentSignUpSite.acceptTermsAndConditions == 1 ? false : true} text={labels.next_label} type='button' btnType={constants.notification_type.success} className="showToolTip" />
                                        <span class="tooltiptext">{labels.messages.accept_terms}</span> */}
                                    </div>


                                </form>
                            </div>
                        </div>
                        {this.props.currentSignUpUser && this.props.currentSignUpUser.isProvider ?
                            <div className='row col-lg-3 col-md-12 col-sm-12 col-xs-12 profile-pic-section mt-5'>
                                <div className='d-lg-none col-md-3 col-sm-3 col-xs-3'></div>
                                <div className='image-upload col-lg-12 col-md-9 col-sm-9 col-xs-9'>
                                    <label for='fileInput' className='image-input'>
                                        <img alt={this.props.currentSignUpUser ? `${this.props.currentSignUpUser.firstname}${labels.provider_labels.avatar}` : `${labels.provider_labels.provider_name.toLowerCase()}${labels.provider_labels.avatar}`}
                                            src={this.props.currentSignUpUser && this.props.currentSignUpUser.avatarContent ? `data:${this.props.currentSignUpUser.avatarContentType};base64,${this.props.currentSignUpUser.avatarContent}` : userIcon} />

                                    </label>
                                    {this.props.currentSignUpUser && this.props.currentSignUpUser.avatarContent ? <span>
                                        <i className='fa fa-times-circle danger' title='Remove picture' onClick={(e) => this.props.removeSignUpUserPic(e)}>{labels.provider_labels.remove_pic}</i>
                                    </span> : null}
                                    <input id='fileInput' type='file' onChange={(e) => this.props.uploadSignUpUserPic(e)} accept='image/*' />
                                    <p className='help-block'>{labels.provider_labels.profile_pic}</p>
                                </div>
                            </div> : ''}
                    </div>
                    : <SignupPaymentGateway history={this.props.history} />}
            </React.Fragment>
        )
    }
}


export default SignUp;