import axios from '../../../lib/axios';
import API_URL from '../../../config/api'
import {
    toggleNotification,
    toggleLoading,
    setPageTitle,
    setBreadCrumbs,
    handleErrors,
    handleStatusCodes,
    setLoggedInUser,
    handleLoading,
    noHeaderLoading
} from '../../../reducers/global-reducer';
import { setSearchSite } from '../../ELK/modules/credittransactions';
import constants from '../../../config/constants';
import labels from '../../../config/localization';
import messages from '../../../config/messages';
import { clearPromises, promisesList } from '../../../utils/resolve-promises';
import _, { range } from 'lodash';
import en_labels from '../../../config/en_labels';
import { downloadCSV } from '../../../utils/download-csv-table';
import { isFirefox, b64toBlob } from '../../../utils/download-utils';
import { getDateFormat } from '../../Analytics/tools/helpers';
import { downloadExcel, downloadExcelImproved } from '../../../utils/download-utils';
import moment from 'moment';
import resolveSearchFilters from '../../../utils/resolve-search-params';
import cloneDeep from 'lodash/cloneDeep';


const SET_PROPS = 'SET_PROPS';
const reqFields = ['languagePreference', 'name', 'unitPreference', 'category', 'billingStrategy', 'code']
const reqFieldText = {
    languagePreference: labels.sites_labels.language_preference,
    name: labels.sites_labels.name_label,
    unitPreference: labels.sites_labels.unit_preference,
    category: labels.sites_labels.category_label,
    address: labels.sites_labels.address_label,
    city: labels.sites_labels.city_label,
    state: labels.sites_labels.state_label,
    postalCode: labels.sites_labels.postal_code,
    billingStrategy: labels.sites_labels.billing_strategy,
    code: 'GPA Noumenon'
}
const referalReqFields = [
    'address',
    'city',
    'state',
    'postalCode']
const attendeeDetails = {
    id: en_labels.patient_list_labels.patient_id,
    firstname: en_labels.user_labels.firstname,
    lastname: en_labels.user_labels.lastname,
    email: en_labels.user_labels.email,
    // conference: constants.conference_category,
    lastActiveDate: en_labels.user_labels.latest_visit_date
}

const SitesListlabels = {
    id: labels.sites_labels.id,
    name: labels.sites_labels.name_label,
    category: labels.sites_labels.category_label,
    billing: labels.sites_labels.billing_strategy,
    latestVisitDate: labels.sites_labels.last_visit,
    totalVisitCount: labels.sites_labels.total_visits,
    providers: labels.sites_labels.total_providers,
    patients: labels.sites_labels.total_patients,
    units: labels.sites_labels.units_system,
    active: labels.sites_labels.status
}

const openbleFiles = ['pdf', 'jpg', 'png', 'jpeg']
// function handleLoading() {
//     return (dispatch) => {
//         let allPromises = promisesList.filter(p => p)
//         Promise.all(allPromises).then(function (values) {
//             if (allPromises.length && allPromises.length == values.length) {
//                 clearPromises()
//                 dispatch(toggleLoading(false))
//             }
//         });
//     }
// }
const requiredFieldsForNoumenon = ['noumenonName', 'units']
const requiredFieldsForRubric = ['gender']
const requiredFieldsForRange = ['type']
const requiredFieldTextForNoumenon =
{
    noumenonName: 'Noumenon Name',
    units: 'Units',
}

const requiredFieldTextForRubric =
{
    gender: "Gender"
}
const requiredFieldTextForRange =
{
    type: "Type"
}
function getSites() {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        dispatch(toggleNotification(false))
        dispatch({
            type: SET_PROPS,
            payload: {
                allSites: [],
                filteredSites: [],
                searchParam: {
                    searchFilters: []
                },
                siteNameList: []
            }
        })

        let searchParam = _.cloneDeep(getState().sites.searchParam);
        let currentSearchFilters = resolveSearchFilters(searchParam.searchFilters)
        dispatch({
            type: SET_PROPS,
            payload: {
                currentSearchFilters
            }
        })
        searchParam.searchFilters = searchParam.searchFilters.filter(u => u.fieldValue != 'all')
        promisesList.push(axios.get(API_URL.SITES_LIST_URL).then((response) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    allSites: response.data ? response.data.sitesDTOs : [],
                    filteredSites: response.data ? response.data.sitesDTOs : [],
                    siteSpecs: { ...response.data },
                    no_grid_data: response.data && response.data.sitesDTOs && !response.data.sitesDTOs.length ? true : false
                }
            })
            dispatch(setSiteNameList())
            setTimeout(() => {
                dispatch(setPageTitle(null))
                dispatch(setBreadCrumbs([]))
            });
            dispatch(handleLoading())
        }).catch((error) => {
            dispatch(handleErrors(error))
        }))
    }
}

export function setSiteSearchParam(val, key) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let searchParam = Object.assign({}, getState().sites.searchParam)

        if (searchParam.searchFilters) {
            if (searchParam.searchFilters.filter(sf => sf.fieldName.toLowerCase() == key.toLowerCase()).length) {
                searchParam.searchFilters.map(sf => {
                    if (sf.fieldName == key) { sf.fieldValue = val }
                })
            } else {
                searchParam.searchFilters.push({
                    fieldName: key,
                    fieldValue: val
                })
            }
        } else {
            searchParam.searchFilters = []
            searchParam.searchFilters.push({
                fieldName: key,
                fieldValue: val
            })
        }
        let currentSearchFilters = resolveSearchFilters(searchParam.searchFilters)
        dispatch({
            type: SET_PROPS,
            payload: {
                searchParam: searchParam,
                currentSearchFilters
            }
        })
    }
}

export function populateSitesSearchParams(name) {
    return (dispatch, getState) => {
        let allSitesNames = getState() && getState().sites && getState().sites.siteNameList ? getState().sites.siteNameList : []
        let currentSitesName = []
        if (name) {
            allSitesNames.map(an => {
                if (an.name.toLowerCase().indexOf(name.toLowerCase()) != -1 && currentSitesName.indexOf(an.name) == -1)
                    currentSitesName.push(an.name)
            })
        }

        dispatch({
            type: SET_PROPS,
            payload: {
                currentSitesName
            }
        })
    }
}

export function filterSitesList(searchFieldName, searchFieldActive) {
    return (dispatch, getState) => {
        let filteredSites = getState().sites && getState().sites.allSites
            && getState().sites.allSites.length
            ? [...getState().sites.allSites]
            : []

        if (searchFieldName) {
            let search = searchFieldName.toLowerCase()
            filteredSites = filteredSites.filter(s => s.name.toLowerCase().indexOf(search) > -1)
        }

        if (searchFieldActive && searchFieldActive != 'all') {
            filteredSites = filteredSites.filter(s => s.active == searchFieldActive)
        }

        dispatch({
            type: SET_PROPS,
            payload: {
                searchFieldName: searchFieldName ? searchFieldName.toLowerCase() : null,
                searchFieldActive: searchFieldActive ? searchFieldActive : null,
                filteredSites
            }
        })
    }
}

export function setSiteNameList() {
    return (dispatch, getState) => {
        let allSitesNames = getState() && getState().sites.allSites ? getState().sites.allSites.map(selectProps("id", "name")) : []
        dispatch({
            type: SET_PROPS,
            payload: {
                siteNameList: allSitesNames
            }
        })
    }
}

function selectProps(...props) {
    return function (obj) {
        const newObj = {};
        props.forEach(name => {
            newObj[name] = obj[name];
        });
        return newObj;
    }
}

export function clearSiteNames() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentSitesName: []
            }
        })
    }
}

export function clearSiteNamesOnClick(event) {
    return (dispatch) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentSitesName: []
                }
            })
        }
    }
}

function getPanels() {
    return (dispatch) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(API_URL.PANELS_DATA).then(response => {
            let panelList = _.orderBy(response.data.panelsDTO, [panel => panel.name.toLowerCase()], ['asc']);
            dispatch({
                type: SET_PROPS,
                payload: {
                    panelList
                }
            })
            dispatch(handleLoading())
        }).catch(error => {
            dispatch(handleErrors(error))
        }))
    }
}
function setCurrentSiteDetails(siteId, breadcrumbType, history, docId) {
    return (dispatch) => {
        dispatch(setCurrentSite(siteId, true, breadcrumbType, history, docId))
        // dispatch(GetAllNoumenonNames())
    }
}
function setCurrentSite(siteId, details, breadcrumbType, history, docId) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                currentSite: {},
                currentDoc: {}
            }
        })
        let CreateNoumenonList = [];
        
        if (siteId) {
            promisesList.push(axios.get(`${API_URL.SITES_URL}/${siteId}`).then(response => {
                if (breadcrumbType == "document") {
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            publicationDate: null,
                            currentDoc: null
                        }
                    })
                    dispatch(getDocumentsWithSiteId(siteId))
                }
                let currentSite = Object.assign({}, response.data, { contactInfo: [response.data.address, response.data.state, response.data.phone ? `Phone: ${response.data.phone}` : ''] })
                let basicPlan = currentSite.planType == constants.planTypeBasic
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        currentSite,
                        basicPlan
                    }
                })
                CreateNoumenonList = currentSite.noumenonCodes.map(noumenon => 
                    ({
                        Id: noumenon.id,
                        code: noumenon.code,
                        startDate: noumenon.startDate,
                        endDate: noumenon.endDate,
                        uuid: setUuid(),
                    })
                )
                if (currentSite.noumenonCodes == 0){
                    CreateNoumenonList = 
                        [{
                            Id: 0,
                            code: '',
                            startDate: '',
                            endDate: '',
                            uuid: setUuid(),
                        }]
                }
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        CreateNoumenonList: CreateNoumenonList
                    }
                })
                


                if (breadcrumbType == "document") {
                    history.push(`/sites/${currentSite.id}/documents`)
                    dispatch(isDocumentFeatureEnabled(history))
                    document.title = `${labels.physioage_label} : ${labels.document_list_for} ${currentSite.name}`
                    dispatch(setBreadCrumbs([
                        { text: labels.physioage_label, path: '/patients/list' },
                        { text: currentSite.name, path: `/sites/${currentSite.id}` },
                        { text: labels.patient_downloads, path: '' }
                    ]))
                    dispatch(setPageTitle(`${labels.document_list_for} ${currentSite.name}`))
                }
                else if (breadcrumbType == 'upload-document') {
                    dispatch(isDocumentFeatureEnabled(history))
                    document.title = `${labels.physioage_label} : ${labels.document_labels.upload_document_for} ${currentSite.name}`
                    dispatch(setBreadCrumbs([
                        { text: labels.physioage_label, path: '/patients/list' },
                        { text: currentSite.name, path: `/sites/${currentSite.id}` },
                        { text: labels.patient_downloads, path: '' }
                    ]))
                    dispatch(setPageTitle(`${labels.document_labels.upload_document_for} ${currentSite.name}`))
                }
                else if (breadcrumbType == 'update-document') {
                    if (docId)
                        dispatch(getDocument(docId, siteId))
                    dispatch(isDocumentFeatureEnabled(history))
                    document.title = `${labels.physioage_label} : ${labels.document_labels.edit_document}`
                    dispatch(setBreadCrumbs([
                        { text: labels.physioage_label, path: '/patients/list' },
                        { text: currentSite.name, path: `/sites/${currentSite.id}` },
                        { text: labels.patient_downloads, path: '' }
                    ]))
                    dispatch(setPageTitle(labels.document_labels.edit_document))
                }
                else if (breadcrumbType == 'customReferenceRanges') {
                    document.title = `${labels.physioage_label} : ${labels.sites_labels.custom_reference_ranges}`
                    dispatch(setBreadCrumbs([
                        { text: labels.physioage_label, path: '/patients/list' },
                        { text: currentSite.name, path: `/sites/${currentSite.id}` },
                        { text: labels.sites_labels.custom_reference_ranges, path: '' }
                    ]))
                    dispatch(setPageTitle(labels.sites_labels.custom_reference_ranges))
                    dispatch(getCustomReferenceRanges(siteId))
                }
                else if (breadcrumbType == 'custom_reference_range_form_new') {
                    document.title = `${labels.physioage_label} : ${labels.custom_reference_ranges.new_custom_reference_range}`
                    dispatch(setBreadCrumbs([
                        { text: labels.physioage_label, path: '/patients/list' },
                        { text: currentSite.name, path: `/sites/${currentSite.id}` },
                        { text: labels.sites_labels.custom_reference_ranges, path: `/sites/${currentSite.id}/custom_reference_ranges` },
                        { text: labels.custom_reference_ranges.new_custom_reference_range, path: '' }
                    ]))
                    dispatch(setPageTitle(labels.custom_reference_ranges.new_custom_reference_range))
                }

                else if (breadcrumbType == 'custom_reference_range_form_edit') {
                    document.title = `${labels.physioage_label} : ${labels.custom_reference_ranges.edit_custom_reference_range}`
                    dispatch(setBreadCrumbs([
                        { text: labels.physioage_label, path: '/patients/list' },
                        { text: currentSite.name, path: `/sites/${currentSite.id}` },
                        { text: labels.sites_labels.custom_reference_ranges, path: `/sites/${currentSite.id}/custom_reference_ranges` },
                        { text: labels.custom_reference_ranges.edit_custom_reference_range, path: '' }
                    ]))
                    dispatch(setPageTitle(labels.custom_reference_ranges.edit_custom_reference_range))
                }
                else if (breadcrumbType == 'customizations') {
                    dispatch(customNoumenonListPage(history, [currentSite]))
                    history.push(`/sites/${currentSite.id}/customizations`)
                    document.title = `${labels.physioage_label} : ${labels.custom_noumenon_list.customize_noumenon_list}`
                    dispatch(setBreadCrumbs([
                        { text: labels.physioage_label, path: '/patients/list' },
                        { text: currentSite.name, path: `/sites/${currentSite.id}` },
                        { text: labels.custom_noumenon_list.customize_noumenon_list, path: '' }
                    ]))
                    dispatch(setPageTitle(labels.custom_noumenon_list.customize_noumenon_list))
                    dispatch(getCustomNoumenonList(siteId))
                }
                else if (breadcrumbType == 'customizations_form_new') {
                    document.title = `${labels.physioage_label} : ${labels.custom_noumenon_list.new_customize_noumenon}`
                    dispatch(setBreadCrumbs([
                        { text: labels.physioage_label, path: '/patients/list' },
                        { text: currentSite.name, path: `/sites/${currentSite.id}` },
                        { text: labels.custom_noumenon_list.customize_noumenon, path: `/sites/${currentSite.id}/customizations` },
                        { text: labels.custom_noumenon_list.new_customize_noumenon, path: '' }
                    ]))
                    dispatch(setPageTitle(labels.custom_noumenon_list.new_customize_noumenon))
                }

                else if (breadcrumbType == 'customizations_form_edit') {
                    document.title = `${labels.physioage_label} : ${labels.custom_noumenon_list.edit_customize_noumenon}`
                    dispatch(setBreadCrumbs([
                        { text: labels.physioage_label, path: '/patients/list' },
                        { text: currentSite.name, path: `/sites/${currentSite.id}` },
                        { text: labels.custom_noumenon_list.customize_noumenon, path: `/sites/${currentSite.id}/customizations` },
                        { text: labels.custom_noumenon_list.edit_customize_noumenon, path: '' }
                    ]))
                    dispatch(setPageTitle(labels.custom_noumenon_list.edit_customize_noumenon))
                }
                else if (breadcrumbType == 'customizations_details') {
                    let noumenonName = getState().sites && getState().sites.noumenonName ? getState().sites.noumenonName : ''
                    document.title = `${labels.physioage_label} : ${labels.custom_noumenon_list.noumenon} : ${noumenonName}`
                    dispatch(setBreadCrumbs([
                        { text: labels.physioage_label, path: '/patients/list' },
                        { text: currentSite.name, path: `/sites/${currentSite.id}` },
                        { text: labels.custom_noumenon_list.customize_noumenon, path: `/sites/${currentSite.id}/customizations` },
                        { text: `${labels.custom_noumenon_list.noumenon} ${noumenonName}`, path: '' }
                    ]))
                    dispatch(setPageTitle(`${labels.custom_noumenon_list.noumenon} ${noumenonName} `))
                }
                else if (breadcrumbType == 'rubric_form_new') {
                    document.title = `${labels.physioage_label} : ${labels.custom_noumenon_list.new_rubric}`
                    dispatch(setBreadCrumbs([
                        { text: labels.physioage_label, path: '/patients/list' },
                        { text: currentSite.name, path: `/sites/${currentSite.id}` },
                        { text: labels.custom_noumenon_list.customize_noumenon, path: `/sites/${currentSite.id}/customizations/` },
                        { text: getState().sites.currentRubric && getState().sites.currentRubric.noumenonCode ? getState().sites.currentRubric.noumenonCode : "", path: `/sites/${currentSite.id}/customizations/${getState().sites.currentRubric.noumenonCode}` },
                        { text: labels.custom_noumenon_list.new_rubric, path: '' }
                    ]))
                    dispatch(setPageTitle(labels.custom_noumenon_list.new_rubric))
                }
                else if (breadcrumbType == 'rubric_form_edit') {
                    document.title = `${labels.physioage_label} : ${labels.custom_noumenon_list.edit_rubric}`
                    dispatch(setBreadCrumbs([
                        { text: labels.physioage_label, path: '/patients/list' },
                        { text: currentSite.name, path: `/sites/${currentSite.id}` },
                        { text: labels.custom_noumenon_list.customize_noumenon, path: `/sites/${currentSite.id}/customizations/` },
                        { text: getState().sites.currentRubric && getState().sites.currentRubric.noumenonCode ? getState().sites.currentRubric.noumenonCode : "", path: `/sites/${currentSite.id}/customizations/${getState().sites.currentRubric.noumenonCode}` },
                        { text: getState().sites.currentRubric && getState().sites.currentRubric.gender ? getState().sites.currentRubric.gender : constants.ALL, path: `/sites/${currentSite.id}/customizations/${getState().sites.currentRubric.noumenonCode}` },
                        { text: labels.custom_noumenon_list.edit_rubric, path: '' }
                    ]))
                    dispatch(setPageTitle(labels.custom_noumenon_list.edit_rubric))
                }
                else if (breadcrumbType == 'range_form_new') {
                    document.title = `${labels.physioage_label} : ${labels.custom_noumenon_list.new_range}`
                    dispatch(setBreadCrumbs([
                        { text: labels.physioage_label, path: '/patients/list' },
                        { text: currentSite.name, path: `/sites/${currentSite.id}` },
                        { text: labels.custom_noumenon_list.customize_noumenon, path: `/sites/${currentSite.id}/customizations/` },
                        { text: getState().sites.currentRange && getState().sites.currentRange.noumenonCode ? getState().sites.currentRange.noumenonCode : "", path: `/sites/${currentSite.id}/customizations/${getState().sites.currentRange.noumenonCode}` },
                        { text: getState().sites.currentRange && getState().sites.currentRange.gender ? getState().sites.currentRange.gender : constants.ALL, path: `/sites/${currentSite.id}/customizations/${getState().sites.currentRange.noumenonCode}` },
                        { text: labels.custom_noumenon_list.new_range, path: '' }
                    ]))
                    dispatch(setPageTitle(labels.custom_noumenon_list.new_range))
                }
                else if (breadcrumbType == 'range_form_edit') {
                    document.title = `${labels.physioage_label} : ${labels.custom_noumenon_list.edit_range}`
                    dispatch(setBreadCrumbs([
                        { text: labels.physioage_label, path: '/patients/list' },
                        { text: currentSite.name, path: `/sites/${currentSite.id}` },
                        { text: labels.custom_noumenon_list.customize_noumenon, path: `/sites/${currentSite.id}/customizations/` },
                        { text: getState().sites.currentRange && getState().sites.currentRange.noumenonCode ? getState().sites.currentRange.noumenonCode : "", path: `/sites/${currentSite.id}/customizations/${getState().sites.currentRange.noumenonCode}` },
                        { text: getState().sites.currentRange && getState().sites.currentRange.gender ? getState().sites.currentRange.gender : constants.ALL, path: `/sites/${currentSite.id}/customizations/${getState().sites.currentRange.noumenonCode}` },
                        { text: getState().sites.currentRange && getState().sites.currentRange.type ? constants.type.find(t => t.value == getState().sites.currentRange.type).text : "", path: `/sites/${currentSite.id}/customizations/${getState().sites.currentRange.noumenonCode}` },
                        { text: labels.custom_noumenon_list.edit_range, path: '' }
                    ]))
                    dispatch(setPageTitle(labels.custom_noumenon_list.edit_range))
                }
                else if (details) {
                    dispatch(setPageTitle(currentSite ? currentSite.name : ''))
                    document.title = `${labels.physioage_label}:${currentSite ? currentSite.name : ''}`
                    dispatch(setBreadCrumbs([
                        { text: labels.physioage_label, path: '/patients/list' },
                        { text: currentSite ? currentSite.name : '', path: '' }
                    ]))
                } else {
                    dispatch(setPageTitle(`${labels.sites_labels.edit_site} ${currentSite ? currentSite.name : ''}`))
                    document.title = `${labels.physioage_label}: ${labels.sites_labels.edit_site} ${currentSite ? currentSite.name : ''}`
                    dispatch(setBreadCrumbs([
                        { text: labels.physioage_label, path: '/patients/list' },
                        { text: `${labels.sites_labels.edit_site} ${currentSite ? currentSite.name : ''}`, path: '' }
                    ]))

                }
                if (breadcrumbType != 'customizations_form_edit'){
                    dispatch(handleLoading())
                }    
            }).catch(error => {
                dispatch(toggleLoading(false))
                dispatch(handleStatusCodes(error))
                dispatch(handleErrors(error))
            }))
        } else {
            dispatch(toggleLoading(false))
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentSite: {
                        languagePreference: _.head(constants.language_preference).code,
                        datePreference: _.head(constants.date_preference).code,
                        includePhoneInSummary: 1,
                        acceptsMessages: 1,
                        acceptsReferrals: 1,
                        enableDocuments: 1,
                        active: 1,
                        mfaRequired: 1
                    }
                }
            })
        }
    }
}
function isDocumentFeatureEnabled(history) {
    return (dispatch, getState) => {
        var currentSite = getState().sites.currentSite
        if (!currentSite.enableDocuments) {
            history.push('/statuscodes/403')
        }
    }
}
function logoUploaded(eve) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let uploadedFile = eve.target.files ? eve.target.files[0] : null
        let fieldType = eve.target.id
        let imagetype = uploadedFile.type//.split('/')[uploadedFile.type.split('/').length - 1]
        if (uploadedFile) {
            if (constants.valid_image_types.indexOf(imagetype) != -1) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    let avatarContent = e.target.result;
                    var image = new Image();
                    image.src = e.target.result;
                    image.onload = function () {
                        if (this.width < 150 || this.height < 150) {
                            window.scrollTo(0, 0)
                            dispatch(toggleNotification(true, constants.notification_type.error, '', [messages.pic_size_validation], false))
                        }
                        else {
                            let currentSite = getState() && getState().sites ? getState().sites.currentSite : {}
                            dispatch({
                                type: SET_PROPS,
                                payload: {
                                    currentSite: Object.assign({}, currentSite,
                                        {
                                            [`${fieldType}File`]: avatarContent.split(',')[1],
                                            [`${fieldType}ContentType`]: imagetype,
                                            [`${fieldType}FileSize`]: uploadedFile.size,
                                            [`${fieldType}FileName`]: uploadedFile.name
                                        })
                                }
                            })
                        }
                    }
                }
                reader.readAsDataURL(eve.target.files[0]);
            } else {
                window.scrollTo(0, 0)
                dispatch(toggleNotification(true, constants.notification_type.error, '', [messages.pic_validation_message], false))
            }
        }
    }
}

function editSite(eve) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let currentSite = getState() && getState().sites ? getState().sites.currentSite : {}
        let value = eve.target && eve.target.type != 'checkbox' ? eve.target.value : eve.target.checked ? 1 : 0
        if (eve.target.id == 'category' && value != 'Clinic' && value != '0') {
            currentSite.acceptsReferrals = 0
        }
        else if (eve.target.id == 'category' && value == 'Clinic') {
            currentSite.acceptsReferrals = 1
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                currentSite: Object.assign({}, currentSite, { [eve.target.id]: value })
            }
        })
    }
}
function editPanels(eve) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let currentSite = getState() && getState().sites ? getState().sites.currentSite : {}
        let value = eve && eve.length ? eve.map(e => e.name) : []
        dispatch({
            type: SET_PROPS,
            payload: {
                currentSite: Object.assign({}, currentSite, { defaultPanels: value && value.length ? value.join(',') : '' })
            }
        })
    }
}

function filterSites(mode) {
    return (dispatch, getState) => {
        let allSites = getState() && getState().sites && getState().sites.allSites ? getState().sites.allSites : []
        let filteredSites = []
        if (mode == constants.sites_labels.referral) {
            filteredSites = allSites.filter(as => parseInt(as.acceptReferrals))
            document.title = `${labels.physioage_label}: ${labels.sites_labels.list_sites_filter_title}`
        } else if (mode == constants.sites_labels.all) {
            filteredSites = allSites
            document.title = `${labels.physioage_label}: ${labels.sites_labels.sites_list_label}`
        }

        dispatch({
            type: SET_PROPS,
            payload: {
                filteredSites: Object.assign([], filteredSites),
                isFilteredSites: mode == constants.sites_labels.all ? false : true
            }
        })

    }
}
function validateWebsite(website) {
    var s = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    return s.test(website)
}
function updateSite(currentSite, history) {
    return (dispatch) => {
        dispatch(toggleLoading(true))

        currentSite.noumenonCodes && currentSite.noumenonCodes.forEach(noumenon => {
            noumenon.endDate = noumenon.endDate ? moment(noumenon.endDate).format('YYYY-MM-DD HH:mm:ss') : "";
            noumenon.startDate = noumenon.startDate ? moment(noumenon.startDate).format('YYYY-MM-DD HH:mm:ss') : "";
        });
        currentSite.noumenonCodes = currentSite.noumenonCodes.filter(noumenon => noumenon.code);

        if (currentSite.website && currentSite.website.indexOf('http') == -1 && currentSite.website.indexOf('https') == -1) {
            let validate = validateWebsite(currentSite.website)
            if (validate) {
                currentSite.website = `http://${currentSite.website}`
            }
        }
        promisesList.push(axios[currentSite.id ? 'put' : 'post'](API_URL.SITES_URL, { ...currentSite }).then(response => {
            // dispatch(handleLoading())
            // dispatch(getSites())
            currentSite.id ? history.push(`/sites/${currentSite.id}`) : history.push('/sites/list')
            dispatch(toggleNotification(true, constants.notification_type.success, '',
                [`${currentSite.name} ${labels.successfully_label} ${currentSite.id ? labels.updated_label : labels.added_label}`]
                , false))
            window.scrollTo(0, 0)
            dispatch(setLoggedInUser())
        }).catch(error => {
            dispatch(handleErrors(error))
        }))
    }
}
function saveSite(history) {
    return (dispatch, getState) => {
        let currentSite = getState().sites.currentSite ? getState().sites.currentSite : {}
        if (!currentSite.hasOwnProperty('billingStrategy')) {
            let billingStrategy = _.head(constants.billing_strategy).value
            currentSite = { ...currentSite, billingStrategy }
        }
        let resValid = reqFields.filter(rf => ((!currentSite[rf] && rf != 'code') || (parseInt(currentSite[rf]) == 0 && rf != 'name') || (rf == 'code' && currentSite.noumenonCodes.some(noumenon => (noumenon.code == '' || noumenon.code == '0') && (noumenon.startDate || noumenon.endDate)))))
        if (resValid && resValid.length) {
            dispatch(toggleNotification(true, constants.notification_type.error, '',
                resValid.map(r => `${reqFieldText[r]} ${labels.required_label}`)
                , false))
            dispatch(toggleLoading(false))
            window.scrollTo(0, 0)
        } else if (currentSite.acceptsReferrals && parseInt(currentSite.acceptsReferrals)) {
            let errorMsg = []
            if (currentSite.category != 'Clinic') {
                errorMsg.push(messages.clinic_validation)
            }
            let resultFields = referalReqFields.filter(rq => !currentSite[rq])
            if (resultFields && resultFields.length) {
                resultFields.map(rf => {
                    errorMsg.push(`${reqFieldText[rf]} ${labels.required_label}`)
                })
                dispatch(toggleNotification(true, constants.notification_type.error, '', errorMsg, false))
                dispatch(toggleLoading(false))
                window.scrollTo(0, 0)
            } else {
                dispatch(updateSite(currentSite, history))
            }
        } else {
            dispatch(updateSite(currentSite, history))
        }

    }
}
function clearFilteredSites() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                isFilteredSites: false
            }
        })
    }
}
function addSite(history) {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentSite: {
                    languagePreference: _.head(constants.language_preference).code,
                    datePreference: _.head(constants.date_preference).code,
                    includePhoneInSummary: 1,
                    mfaRequired: 1
                }
            }
        })
        history.push('/sites/new')
    }
}
function siteTransaction(id, history) {
    return (dispatch, getState) => {
        // dispatch(setSearchSite(id))
        // setTimeout(() => {
        history.push(`/credit-transaction/list?siteId=${id}`)
        // })
    }
}
function navigateSite(history, path) {
    return (dispatch) => {
        history.push(path)
        dispatch(toggleNotification(false))
        dispatch({
            type: SET_PROPS,
            payload: { currentSite: {} }
        })
    }
}
export function screenSize() {
    return (dispatch) => {
        var mobile = 'true'
        let width = screen.width
        if (width <= 500) {
            mobile = 'false'
        }
        dispatch({
            type: SET_PROPS,
            patload: {
                isMobile: mobile
            }
        })

    }

}
function downloadAttendeeList() {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let currentSite = getState().sites.currentSite
        let patients = currentSite.patients
        // let csv = []
        // csv.push(Object.values(attendeeDetails).join())
        // if (patients.length) {
        //     patients.map(p => {
        //         csv.push(
        //             `${Object.keys(attendeeDetails).map(a => (p[a] && a != 'conference') ? p[a] : a == 'conference' ? currentSite.name : '""').join()}`
        //         )
        //     })
        // }
        // downloadCSV(csv.join('\n'), constants.attendees_filename)
        const headers = Object.keys(attendeeDetails).map(r => attendeeDetails[r])
        let csv = [headers]
        if (patients && patients.length) {
            patients.map(p => {
                csv.push(
                    Object.keys(attendeeDetails).map(a => a == 'lastActiveDate' ? (p[a] ? moment(p[a]).format(getDateFormat(currentSite.datePreference)) : null) : p[a])
                )
            })
        }
        downloadExcelImproved(csv, en_labels.header_labels.patients, constants.attendees_filename_xlsx)
        dispatch(toggleLoading(false))
    }
}
export function GetReferralSites(acceptRef) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(`${API_URL.SITE_REFERRAL}?accepts_referrals=${acceptRef}`).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    allSitesRef: response.data,
                }
            })
        })
            .catch((error) => {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        referralError: "You are not allowed to do that.",
                    }
                })
                //dispatch(handleStatusCodes(error))
                // dispatch(handleErrors(error))
            }))
    }
}

export function removePic(eve, changeInputVal, fieldType) {
    return (dispatch, getState) => {
        eve.preventDefault()
        changeInputVal.inputEntry.value = ""
        let currentSite = getState().sites.currentSite ? getState().sites.currentSite : {}
        dispatch({
            type: SET_PROPS,
            payload: {
                currentSite: Object.assign({}, currentSite, {
                    [`${fieldType}File`]: null,
                    [`${fieldType}ContentType`]: null,
                    [`${fieldType}FileSize`]: null,
                    [`${fieldType}FileName`]: null
                })
            }
        })
    }
}
export function setSiteDocuments(siteId) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(`${API_URL.SITES_DOCUMENTS_URL}?siteId=${siteId}`).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    allSiteDocuments: response.data
                }
            })
            dispatch(handleLoading())
        }).catch(errors => {
            dispatch(handleErrors(errors))
        }))
    }
}
function getAvatarType(avatarContent) {
    if (avatarContent)
        return avatarContent.split(',')[0].split(';')[0].split(':')[1]
    else return ''
}
export function postFile(eve) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let uploadedFile = eve.target.files ? eve.target.files[0] : null
        if (uploadedFile) {
            let doctype = uploadedFile.type
            //if (constants.valid_doc_types_for_documents.indexOf(doctype) != -1) {
            var reader = new FileReader();
            reader.onload = function (e) {
                let avatarContent = e.target.result
                let currentDoc = getState().sites.currentDoc ? getState().sites.currentDoc : {}
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        currentDoc: Object.assign({}, currentDoc,
                            {
                                documentFile: avatarContent.split(',')[1],
                                fileContentType: doctype ? doctype : getAvatarType(avatarContent),
                                fileFileName: uploadedFile.name,
                                fileFileSize: uploadedFile.size,
                                title: getState().sites && getState().sites.currentDoc.title && getState().sites.currentDoc.title == uploadedFile.name.replace(/\.[^/.]+$/, '') ? getState().sites.currentDoc.title : uploadedFile.name.replace(/\.[^/.]+$/, '')
                            }),
                    }
                })
            }
            reader.readAsDataURL(eve.target.files[0]);
            // }
            // else {
            //     dispatch(toggleNotification(true, constants.notification_type.error, '', "select valid file", false))
            // }
        }
    }
}

export function siteDocumentInput(eve) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let currentDoc = getState() && getState().sites ? getState().sites.currentDoc : {}
        if (eve.target.type == 'checkbox') {
            eve.target.value = eve.target.checked ? 1 : 0
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                currentDoc: { ...currentDoc, [eve.target.id]: eve.target.value }
            }
        })
    }
}
export function handlePublicationDate(eve) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let currentDoc = Object.assign({}, getState().sites.currentDoc, {
            publicationDate: eve
        })
        dispatch({
            type: SET_PROPS,
            payload: {
                currentDoc,
                publicationDate: eve
            }
        })
    }
}
function uploadDocument(history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let currentDoc = { ...getState().sites.currentDoc }
        currentDoc = Object.assign({}, currentDoc,
            {
                siteId: getState().sites ? getState().sites.currentSite.id : 0,
                visible: currentDoc.id ? parseInt(currentDoc.visible) : 1,
                sourceType: 'User',
                sourceId: getState().users ? getState().users.loggedInUser.id : 0,
                publicationDate: currentDoc.id ? (getState().sites.publicationDate ? getState().sites.publicationDate : currentDoc.publicationDate) :
                    currentDoc.publicationDate ? currentDoc.publicationDate : null
            })
        dispatch({
            type: SET_PROPS,
            payload: {
                currentDoc
            }
        })
        if (currentDoc.title && currentDoc.title.length < 4) {
            dispatch({
                type: SET_PROPS,
                payload: {
                    titleError: 'is too short (minimum is 4 characters)'
                }
            })
            dispatch(toggleNotification(true, constants.notification_type.error, '',
                ['Title is too short (minimum is 4 characters)']))
            dispatch(toggleLoading(false))
        }
        else if ((currentDoc.documentFile || currentDoc.id) && currentDoc.title) {
            var params = cloneDeep(getState().patients.currentDoc);
            params.publicationDate = params.publicationDate ? moment(params.publicationDate).format('L') : params.publicationDate;
            promisesList.push(axios[getState().sites.currentDoc.id ? 'put' : 'post'](API_URL.DOCUMENT_URL, { ...params }).then(response => {
                dispatch(toggleLoading(false))
                history.push(`/sites/${getState().sites.currentSite.id}/documents`)
            }).catch(errors => {
                dispatch(handleErrors(errors))
                dispatch(handleStatusCodes(errors))

            }))
        }
    }
}
export function getDocumentsWithSiteId(siteId) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(`${API_URL.DOCUMENT_WITH_GENERIC_ID}?id=${siteId}&ownedBy=site`).then(response => {
            dispatch(toggleLoading(false))
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentSiteDocuments: response.data.value
                }
            })
        }).catch(errors => {
            dispatch(handleErrors(errors))
            dispatch(handleStatusCodes(errors))

        }))
    }
}
export function getDocument(documentId, siteId) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let documentUrl = siteId ? `${API_URL.DOCUMENT_WITH_ID}?documentId=${documentId}&type=site&id=${siteId}`
            : `${API_URL.DOCUMENT_WITH_ID}?documentId=${documentId}`
        promisesList.push(axios.get(documentUrl).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentDoc: response.data.value
                }
            })
            dispatch(handleLoading())
        }).catch(errors => {
            dispatch(handleErrors(errors))
            dispatch(handleStatusCodes(errors))
        }))
    }
}
export function downloadSiteDocument(doc, history) {
    return (dispatch, getState) => {
        // if (openbleFiles.indexOf(doc.format) != -1) {
        //     history.push(`/documents/${doc.documentId}.${doc.format}`)
        // }
        // else {
        dispatch(toggleLoading(true))
        dispatch(noHeaderLoading(true))
        promisesList.push(axios.get(`${API_URL.DOCUMENT_WITH_ID}?documentId=${doc.documentId}`).then(response => {
            let responseData = response.data ? response.data.value : null

            const downloadLink = document.createElement('a');
            if (!isFirefox()) {
                let pdfblob = b64toBlob(`data:${responseData.fileContentType};base64,` + responseData.documentFileContent);
                downloadLink.href = URL.createObjectURL(pdfblob)
            } else {
                downloadLink.href = `data:${responseData.fileContentType};base64,` + responseData.documentFileContent
            }

            downloadLink.className = 'hidden';
            document.body.appendChild(downloadLink);

            downloadLink.setAttribute('download', `${responseData.title}.${responseData.fileContentType.split('/').pop()}`);
            downloadLink.click();
            window.addEventListener('focus', e => { setTimeout(() => downloadLink.remove(), 300); URL.revokeObjectURL(downloadLink.href) }, { once: true });
            dispatch(handleLoading())
            dispatch(noHeaderLoading(false))

        }).catch(error => {
            dispatch(handleErrors(error))
        }))

        // }
    }
}
const ACTION_HANDLERS = {
    [SET_PROPS]: (state, action) => {
        return Object.assign({}, state, { ...action.payload })
    }
}

const initialState = {
    isFilteredSites: false,
    currentSite: {},
    no_grid_data: false,
    referralError: null,
    CreateDocumentList: [],
    currentRange: {},
    currentRubric: {},
    rubrics: [],
    currentCustomNoumenon: {},
    CreateNoumenonList: [],
    basicPlan: false,
    currentCustomReferenceRange: {}
}

export default (state = initialState, action) => {
    const handler = ACTION_HANDLERS[action.type]

    return handler ? handler(state, action) : state
}

export function downloadSitesList() {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        var siteData = getState().sites && getState().sites.filteredSites ? getState().sites.filteredSites : getState().sites.allSites
        if (siteData.length) {
            const headers = Object.keys(SitesListlabels).map(r => {
                return SitesListlabels[r]
            })
            const csv = [headers]
            siteData.map(c => csv.push(Object.keys(SitesListlabels).map(r => {
                if (r == 'active') {
                    return c[r] ? 'Active' : 'Inactive'
                }
                else if (r == 'latestVisitDate' && c[r]) {
                    return moment(new Date(c[r])).format('L')
                }
                else {
                    return c[r]
                }
            })))
            downloadExcelImproved(csv, en_labels.header_labels.sites, constants.sites_filename_xlsx)
            dispatch(toggleLoading(false))
        }
        dispatch(toggleLoading(false))
    }
}


function setUuid() {
    const min = 1;
    const max = 1000;
    const rand = min + Math.random() * (max - min);
    return rand
}

export function setDocumentsFields() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: [
                    {
                        fileFileName: '',
                        title: '',
                        publicationDate: '',
                        uuid: setUuid(),
                        titleError: ''

                    }
                ]
            }
        })
    }
}

export function addDocumentFields() {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: [
                    ...getState().sites.CreateDocumentList,
                    {
                        fileFileName: '',
                        title: '',
                        publicationDate: '',
                        uuid: setUuid(),
                        titleError: ''

                    }
                ]
            }
        })
    }
}

export function removeDocumentFields(uuid) {
    return (dispatch, getState) => {
        let CreateDocumentList = getState().sites.CreateDocumentList
        CreateDocumentList = CreateDocumentList.filter((cf) => cf.uuid != uuid)
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: [
                    ...CreateDocumentList
                ]
            }
        })
    }
}

export function postDocumentFile(index, eve, uuid) {
    return (dispatch, getState) => {
        let uploadedFile = eve.target.files ? eve.target.files[0] : null
        let CreateDocumentList = getState().sites.CreateDocumentList ? getState().sites.CreateDocumentList : []
        if (uploadedFile) {
            let doctype = uploadedFile.type
            var reader = new FileReader();
            reader.onload = function (e) {
                let avatarContent = e.target.result
                let currentCreateDocumentList = []
                currentCreateDocumentList = {
                    ...getState().sites.CreateDocumentList[index],
                    ...{
                        documentFile: avatarContent.split(',')[1],
                        fileContentType: doctype ? doctype : getAvatarType(avatarContent),
                        fileFileName: uploadedFile.name,
                        fileFileSize: uploadedFile.size,
                        title: getState().sites.CreateDocumentList[index] && getState().sites.CreateDocumentList[index].title && getState().sites.CreateDocumentList[index].title == uploadedFile.name.replace(/\.[^/.]+$/, '') ? getState().sites.CreateDocumentList[index].title : uploadedFile.name.replace(/\.[^/.]+$/, '')
                    }
                }
                let fileError = false;
                let titleError = false;
                if (!fileError) {
                    if (!(currentCreateDocumentList.documentFile)) {
                        currentCreateDocumentList.fileError = "File can't be blank"
                        fileError = true
                    }
                    else {
                        currentCreateDocumentList.fileError = ""
                        fileError = false
                    }
                }

                if (!titleError) {
                    if (!currentCreateDocumentList.title) {
                        currentCreateDocumentList.titleError = "Title can't be blank"
                        titleError = true
                    }
                    else {
                        currentCreateDocumentList.titleError = ""
                        titleError = false
                    }
                }

                if (!titleError) {
                    if ((currentCreateDocumentList.title && currentCreateDocumentList.title.length < 4)) {
                        currentCreateDocumentList.titleError = 'Title is too short (minimum is 4 characters)'
                        titleError = true
                    }
                    else {
                        currentCreateDocumentList.titleError = ''
                        titleError = false
                    }
                }
                CreateDocumentList[index] = currentCreateDocumentList
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        CreateDocumentList: [
                            ...CreateDocumentList
                        ]
                    }
                })
                dispatch(addDocumentFields())
            }
            reader.readAsDataURL(eve.target.files[0]);
        }
    }
}

export function handlePublicationDateDocumentFile(index, eve, uuid) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let CreateDocumentList = getState().sites.CreateDocumentList
        CreateDocumentList[index].publicationDate = eve
        if (CreateDocumentList[index].publicationDate && CreateDocumentList[index].fileFileName == "") {
            CreateDocumentList[index].fileError = "File can't be blank"
        }
        else {
            CreateDocumentList[index].fileError = ""
        }

        if (CreateDocumentList[index].publicationDate && CreateDocumentList[index].title == "") {
            CreateDocumentList[index].titleError = "Title can't be blank"
        }
        else {
            CreateDocumentList[index].titleError = ""
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: [
                    ...CreateDocumentList
                ],
                publicationDate: eve
            }
        })
    }
}

export function handleTitleDocumentFile(index, eve, uuid) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let CreateDocumentList = getState().sites.CreateDocumentList
        CreateDocumentList[index].title = eve.target.value
        if (!CreateDocumentList[index].title) {
            CreateDocumentList[index].titleError = "Title can't be blank"
        }
        else {
            CreateDocumentList[index].titleError = ""
        }

        if ((CreateDocumentList[index].title && CreateDocumentList[index].title.length < 4)) {
            CreateDocumentList[index].titleError = 'Title is too short (minimum is 4 characters)'
        }
        else {
            CreateDocumentList[index].titleError = ''
        }
        if (CreateDocumentList[index].title && CreateDocumentList[index].fileFileName == "") {
            CreateDocumentList[index].fileError = "File can't be blank"
        }
        else {
            CreateDocumentList[index].fileError = ""
        }

        if (CreateDocumentList[index].title == "" && CreateDocumentList[index].fileFileName == "") {
            CreateDocumentList[index].titleError = ""
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: [
                    ...CreateDocumentList
                ],
            }
        })
    }
}

function uploadDocumentList(history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let CreateDocumentList = getState().sites.CreateDocumentList ? getState().sites.CreateDocumentList : []
        if (CreateDocumentList.length > 0) {
            CreateDocumentList.map((cdl, index) => {
                let currentDocument = []
                currentDocument = Object.assign({}, cdl,
                    {
                        siteId: getState().sites ? getState().sites.currentSite.id : 0,
                        visible: cdl.id ? parseInt(cdl.visible) : 1,
                        sourceType: 'User',
                        sourceId: getState().users ? getState().users.loggedInUser.id : 0,
                        publicationDate: cdl.id ? (getState().sites.publicationDate ? getState().sites.publicationDate : cdl.publicationDate) :
                            cdl.publicationDate ? cdl.publicationDate : null
                    })
                CreateDocumentList[index] = currentDocument
            })

            let titleError = false;
            let fileError = false;

            CreateDocumentList.map((cdl) => {
                if (!(cdl.documentFile)) {
                    cdl.fileError = "File can't be blank"
                    fileError = true
                }
                else {
                    cdl.fileError = ""
                    fileError = true
                }
                if (!(cdl.title)) {
                    cdl.titleError = "Title can't be blank"
                    titleError = true
                }
                else {
                    cdl.titleError = ""
                    titleError = false
                }

                if (!titleError) {
                    if ((cdl.title && cdl.title.length < 4)) {
                        cdl.titleError = 'Title is too short (minimum is 4 characters)'
                        titleError = true
                    }
                    else {
                        cdl.titleError = ''
                        titleError = false
                    }
                }
            })

            let validatedFile = CreateDocumentList.filter((cf) => cf.titleError == "" && cf.fileError == "")
            let unValidFile = CreateDocumentList.filter((cf) => cf.fileFileName == "" && (cf.title != "" || cf.publicationDate != null))
            let blankRows = CreateDocumentList.filter((cf) => cf.fileFileName == "" && cf.title == "" && cf.publicationDate == null)
            let blankRowUuids = 0;
            if (CreateDocumentList.length > 1 && blankRows.length > 0) {
                blankRowUuids = blankRows.map((row) => row.uuid)
            }
            if ((titleError || fileError) && validatedFile.length < 1 || unValidFile.length > 0) {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        CreateDocumentList: CreateDocumentList.filter((cf) => cf.uuid != [blankRowUuids])
                    }
                })
                dispatch(toggleLoading(false))
            }
            else if (!fileError || validatedFile.length > 0 && unValidFile.length == 0) {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        CreateDocumentList: validatedFile
                    }
                })
                let CreateDocumentList = cloneDeep(validatedFile)
                let params = { CreateDocumentList }
                params.CreateDocumentList =
                    CreateDocumentList = CreateDocumentList.map(s => {
                        if (s.publicationDate) {
                            s.publicationDate = moment(s.publicationDate).format('L');
                            return s
                        }
                        else { return s }
                    })
                promisesList.push(axios['post'](API_URL.DOCUMENT_URL, { ...params }).then(response => {
                    dispatch(toggleLoading(false))
                    history.push(`/sites/${getState().sites.currentSite.id}/documents`)
                    dispatch(clearDocumentsFields())
                }).catch(errors => {
                    dispatch(handleErrors(errors))
                    dispatch(handleStatusCodes(errors))
                    dispatch(toggleLoading(false))

                }))
            }
        }
        else {
            dispatch(toggleLoading(false))
        }
    }
}

export function clearDocumentsFields() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: []
            }
        })
    }
}

export function showImage(index) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let CreateDocumentList = getState().patients.CreateDocumentList
        CreateDocumentList[index].showImage = true
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: [
                    ...CreateDocumentList
                ],
            }
        })
    }
}

export function hideImage(index) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        let CreateDocumentList = getState().patients.CreateDocumentList
        CreateDocumentList[index].showImage = false
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateDocumentList: [
                    ...CreateDocumentList
                ],
            }
        })
    }
}

export function customNoumenonListPage(history, sites) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentUser: { siteId: sites.id }
            }
        })
        history.push(`/sites/${getState().sites.currentSite.id}/customizations`)
    }
}


export function getCustomNoumenonList(siteId) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(`${API_URL.CUSTOM_NOUMENON_LIST}?siteId=${siteId}`).then(response => {
            dispatch(toggleLoading(false))
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentSiteCustomNoumenonList: response.data
                }
            })
        }).catch(errors => {
            dispatch(handleErrors(errors))
            dispatch(handleStatusCodes(errors))

        }))
    }
}

export function addCustomNoumenon(history) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        var currentSite = getState().sites.currentSite
        dispatch({
            type: SET_PROPS,
            payload: {
                currentCustomNoumenon: {
                    noumenonCode: "",
                    noumenonName: "",
                    units: "",
                    scale: ""
                },
                unitsList: []
            }

        })
        history.push(`/sites/${currentSite.id}/customizations/new`)
    }
}

export function editCustomNoumenon(history, path) {
    return (dispatch) => {
        dispatch(toggleNotification(false))
        dispatch(showNoFormChangesError(false))
        dispatch({
            type: SET_PROPS,
            payload: {
                currentCustomNoumenon: {}
            }
        })
        history.push(path)
    }
}

export function detailsCustomNoumenon(history, path) {
    return (dispatch) => {
        dispatch(toggleNotification(false))
        dispatch(showNoFormChangesError(false))
        dispatch({
            type: SET_PROPS,
            payload: {
                currentCustomNoumenon: {}
            }
        })
        history.push(path)
    }
}

export function setCurrentCustomNoumenon(siteId, code, action) {
    return (dispatch, getState) => {
        let unitsList;
        let noumenonName;
        let noumenonNameList = getState().sites.noumenonNameList ? getState().sites.noumenonNameList : []
        let noumenonListWithValueFormat = getState().sites.noumenonListWithValueFormat ? getState().sites.noumenonListWithValueFormat : []
        let scalePoint = ["0 Point", "1 Point", "2 Point", "3 Point"]
        if (code) {
            promisesList.push(axios.get(`${API_URL.GET_CUSTOM_NOUMENA}?siteId=${siteId}&code=${code}`).then(response => {
                let noumenonCode = response.data.value.noumenonCode
                noumenonName = noumenonNameList.length > 0 ? noumenonNameList.find((noumenon) => noumenon.code == noumenonCode).value : ""
                let units = response.data.value.units
                let customeNoumenon = noumenonListWithValueFormat.find((noumenon) => noumenon.code == noumenonCode)
                if (customeNoumenon && customeNoumenon.valueFormats) {
                    unitsList = customeNoumenon.valueFormats.map((unit) => { return { value: unit } })
                }
                else {
                    unitsList = []
                }
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        currentCustomNoumenon: {
                            noumenonCode: noumenonCode,
                            noumenonName: noumenonName,
                            units: units,
                            id: response.data.value.id,
                            scale: scalePoint[response.data.value.scale]
                        },
                        initialcurrentCustomNoumenon: {
                            noumenonCode: noumenonCode,
                            noumenonName: noumenonName,
                            units: units,
                            scale: scalePoint[response.data.value.scale]
                        },
                        unitsList: unitsList,
                        isNoumenonModal: false,
                    }
                })

                if (action == 'details') {
                    dispatch(getRubrics(response.data.value.reportingNoumenonId, siteId))
                }
                else {
                    dispatch(toggleLoading(false))   
                }
                dispatch(showNoFormChangesError(false));
            }).catch(errors => {
                dispatch(handleErrors(errors))
                dispatch(toggleLoading(false))
            }))
        }
        else {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentCustomNoumenon: {
                        noumenonCode: "",
                        noumenonName: "",
                        units: "",
                        scale: ""
                    },
                    initialcurrentCustomNoumenon: {
                        noumenonCode: "",
                        noumenonName: "",
                        units: "",
                        scale: ""
                    }
                }
            })
            dispatch(toggleLoading(false))
        }
    }
}

export function saveCustomNoumenon(siteId,history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true));
        let currentCustomNoumenon = getState().sites && getState().sites.currentCustomNoumenon ? getState().sites && getState().sites.currentCustomNoumenon : ''
        if (currentCustomNoumenon) {
            let req = requiredFieldsForNoumenon.filter(rf => rf == 'units' ? (parseInt(currentCustomNoumenon['units']) == 0 || !currentCustomNoumenon[rf]) : (!currentCustomNoumenon[rf]))
            if (req && req.length) {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        customizationErrors: req.map(r => { return { field: `${r}`, error: `${requiredFieldTextForNoumenon[r]} ${labels.required_label}` } }),
                        currentCustomNoumenon: {
                            noumenonCode: currentCustomNoumenon.noumenonCode,
                            noumenonName: currentCustomNoumenon.noumenonName,
                            units: currentCustomNoumenon.units
                        },
                    }
                })
                dispatch(toggleLoading(false));
            }
            else {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        customizationErrors: [],
                    }
                })
                let siteId = siteId ? siteId : getState().sites && getState().sites.currentSite && getState().sites.currentSite.id ? getState().sites.currentSite.id : '';
                let units = currentCustomNoumenon.units ? currentCustomNoumenon.units : ''
                let noumenonCode = currentCustomNoumenon.noumenonCode ? currentCustomNoumenon.noumenonCode : '';
                let scale = currentCustomNoumenon.scale ? currentCustomNoumenon.scale : '';
                scale = scale ? parseInt(scale.replace(" Point", "")) : null

                let createCustomSiteUnitsDTO = { siteId, noumenonCode, units, scale, id: 0 };
                promisesList.push(axios[getState().sites.currentCustomNoumenon.id ? 'put' : 'post'](getState().sites.currentCustomNoumenon.id ? API_URL.ÇUSTOM_UNIT_UPDATE : API_URL.PHYSIOAGE_DATA, { ...createCustomSiteUnitsDTO }).then(response => {
                    dispatch(toggleLoading(false));
                    history.push(`/sites/${siteId}/customizations`)
                    let message
                    if (currentCustomNoumenon.id) {
                        message = labels.custom_fields_labels.noumenon_update
                    }
                    else {
                        message = labels.custom_fields_labels.noumenon_save
                    }
                    dispatch(toggleNotification(true, constants.notification_type.success, '',
                        [`${currentCustomNoumenon.noumenonName} ${message}`], true, 3000))
                }).catch(error => {
                    dispatch(toggleLoading(false));
                    dispatch(handleErrors(error))
                }))
            }
        }
    }
}

export function getNoumenonList(siteId, code, history, action, referenceRange) {
    return (dispatch) => {
        dispatch(toggleLoading(true))
        dispatch(showNoFormChangesError(false))
        promisesList.push(axios.get(`${API_URL.GET_NOUMENON_LIST}?siteId=${siteId}`).then(response => {
            let noumenonNameList = response.data.map((rec) => { return { code: rec.code, value: rec.noumenonName, } })
            let noumenonName = code && noumenonNameList.find((noumenon) => noumenon.code == code) && noumenonNameList.find((noumenon) => noumenon.code == code).value ? noumenonNameList.find((noumenon) => noumenon.code == code).value : ''
            dispatch({
                type: SET_PROPS,
                payload: {
                    noumenonListWithValueFormat: response.data,
                    noumenonNameList: noumenonNameList,
                    noumenonName: noumenonName,
                    currentRange: {},
                    currentRubric: {},
                    rubrics: []
                }
            })
            if (referenceRange){
                dispatch(setcurrentCustomReferenceRange(siteId, code, action))
            }
            else {
                dispatch(setCurrentCustomNoumenon(siteId, code, action))
            }    
            if (action == 'details') {
                dispatch(setCurrentSiteDetails(siteId, 'customizations_details', history))
            }    

        }).catch(errors => {
            dispatch(handleErrors(errors))
        }))
    }
}

export function editCustomNoumenonForm(event, recId) {
    return (dispatch, getState) => {
        let currentCustomNoumenon = getState().sites.currentCustomNoumenon
        let noumenonListWithValueFormat = getState().sites.noumenonListWithValueFormat
        let unitsList = getState().sites.unitsList ? getState().sites.unitsList : []
        let customeNoumenon;
        if (recId == 'noumenonCode') {
            if (event != null && getState().sites.currentCustomNoumenon && getState().sites.currentCustomNoumenon.noumenonName != event.value) {
                currentCustomNoumenon = { ...getState().sites.currentCustomNoumenon, noumenonName: event.value, units: '' }
                if (event.code) {
                    currentCustomNoumenon = { ...currentCustomNoumenon, noumenonCode: event.code }
                }
                customeNoumenon = noumenonListWithValueFormat.find((noumenon) => noumenon.noumenonName == event.value)
                if (customeNoumenon && customeNoumenon.valueFormats) {
                    unitsList = customeNoumenon.valueFormats.map((unit) => { return { value: unit } })
                }
                else {
                    unitsList = []
                }
                dispatch(showNoFormChangesError(false, 'noumenonName'));
            }
        }
        else {
            let key = event.target.id
            let value = event.target.value
            if (getState().sites.currentCustomNoumenon && getState().sites.currentCustomNoumenon[key] != value) {
                currentCustomNoumenon = getState().sites.currentCustomNoumenon ? { ...getState().sites.currentCustomNoumenon, [key]: value } : { [key]: value, noumenonCode: '', noumenonName: '' }
            }
            else {
                currentCustomNoumenon = getState().sites.currentCustomNoumenon
            }

        }
        dispatch({
            type: SET_PROPS,
            payload: {
                currentCustomNoumenon,
                unitsList: unitsList
            }
        })
        if (currentCustomNoumenon.units != '' && parseInt(currentCustomNoumenon.units) != 0)
            dispatch(showNoFormChangesError(false, 'units'));
        if (currentCustomNoumenon.noumenonName != '')
            dispatch(showNoFormChangesError(false, 'noumenonName'));
        dispatch(showNoFormChangesError(false))
    }

}

export function showNoFormChangesError(val, field) {
    return (dispatch, getState) => {
        let customizationErrors = getState().analytics.customizationErrors ? getState().analytics.customizationErrors : [];
        if (val)
            customizationErrors = [{ ...customizationErrors, field: "noChanges", error: labels.custom_noumenon_list.no_customize_noumenon_changes }]
        else if (field) {
            customizationErrors = customizationErrors.filter((ce) => ce.field != field)
        }
        else {
            customizationErrors = [];
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                customizationErrors: customizationErrors
            }
        })
    }
}


export function deleteCustomNoumenon(history, siteId) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let code = getState().sites && getState().sites.deleteNoumenonCode
        let noumenonName = getState().sites && getState().sites.currentSiteCustomNoumenonList ? getState().sites.currentSiteCustomNoumenonList.find((noumenon) => noumenon.code == code).name : ""
        promisesList.push(axios.delete(`${API_URL.DELETE_CUSTOM_NOUMENON}?code=${code}&siteId=${siteId}`).then(response => {
            dispatch(toggleLoading(false))
            dispatch(getCustomNoumenonList(siteId))
            dispatch(toggleNoumenonModal())
            dispatch(toggleNotification(true, constants.notification_type.success, '',
                [`${noumenonName} ${labels.custom_fields_labels.noumenon_delete}`], false, 3000))
        }).catch(errors => {
            dispatch(handleErrors(errors))
        }))
    }
}

export function toggleNoumenonModal(modalMode, code) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                modalMode: modalMode ? modalMode : ''
            }
        })
        dispatch({
            type: SET_PROPS,
            payload: {
                isNoumenonModal: getState() && getState().sites ? !getState().sites.isNoumenonModal : false,
                deleteNoumenonCode: code ? code : ''
            }
        })


    }
}


export function deleteDocument(id) {
    return (dispatch, getState) => {
        if (id) {
            dispatch(toggleLoading(true))
            promisesList.push(axios.delete(`${API_URL.DOCUMENT_URL}/${id}`).then(response => {
                let currentSiteId = getState().sites && getState().sites.currentSite ? getState().sites.currentSite.id : 0
                dispatch(getDocumentsWithSiteId(currentSiteId))
            }).catch(error => {
                dispatch(handleErrors(error))
                dispatch(handleStatusCodes(error))
            }))
        }
    }
}

export function addRubric(history, path, id) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        dispatch({
            type: SET_PROPS,
            payload: {
                currentRubric: {
                    noumenonId: id,
                    gender: "",
                    phase: "",
                    startingAge: "",
                    endingAge: "",
                    startingHeight: "",
                    endingHeight: ""
                },
                initialCurrentRubric: {
                    gender: "",
                    phase: "",
                    startingAge: "",
                    endingAge: "",
                    startingHeight: "",
                    endingHeight: ""
                }
            }

        })
        dispatch(showNoFormChangesErrorRubric())
        history.push(path)
    }
}

export function setCurrentRubric(code, siteId, rubric_id) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(`${API_URL.GET_CUSTOM_NOUMENA}?siteId=${siteId}&code=${code}`).then(response => {
            let noumenonId = response.data.value.reportingNoumenonId
            dispatch({
                type: SET_PROPS,
                payload: { noumenonId: noumenonId }
            })
        }).catch(errors => {
            dispatch(handleErrors(errors))
        }))
        let rubric;
        if (rubric_id) {
            promisesList.push(axios.get(`${API_URL.GET_INDIVIDUAL_RUBRIC}?id=${rubric_id}`).then(response => {
                rubric = response.data.value
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        currentRubric: {
                            noumenonCode: code,
                            id: rubric.id,
                            customDeterminantId: rubric.customDeterminantId,
                            reportingNoumenonId: rubric.reportingNoumenonId,
                            gender: rubric.gender ? rubric.gender : constants.ALL,
                            phase: rubric.phase,
                            startingAge: rubric.startingAge,
                            endingAge: rubric.endingAge,
                            startingHeight: rubric.startingHeight,
                            endingHeight: rubric.endingHeight
                        },
                        initialCurrentRubric: {
                            gender: rubric.gender ? rubric.gender : constants.ALL,
                            phase: rubric.phase,
                            startingAge: rubric.startingAge,
                            endingAge: rubric.endingAge,
                            startingHeight: rubric.startingHeight,
                            endingHeight: rubric.endingHeight
                        }
                    }
                })
            }).catch(errors => {
                dispatch(handleErrors(errors))
            }))
        }
        else {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentRubric: {
                        noumenonCode: code,
                        gender: "",
                        phase: "",
                        startingAge: "",
                        endingAge: "",
                        startingHeight: "",
                        endingHeight: ""
                    },
                    initialCurrentRubric: {
                        gender: "",
                        phase: "",
                        startingAge: "",
                        endingAge: "",
                        startingHeight: "",
                        endingHeight: ""
                    }
                }
            })
        }
    }
}

export function showNoFormChangesErrorRubric(val, field) {
    return (dispatch, getState) => {
        let rubricErrors = getState().sites.rubricErrors ? getState().sites.rubricErrors : [];
        if (val)
            rubricErrors = [{ ...rubricErrors, field: "noChanges", error: labels.custom_noumenon_list.no_rubric_changes }]
        else if (field) {
            rubricErrors = rubricErrors.filter((ce) => ce.field != field)
        }
        else {
            rubricErrors = [];
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                rubricErrors: rubricErrors
            }
        })
    }
}

export function showNoFormChangesErrorRange(val, field) {
    return (dispatch, getState) => {
        let rangeErrors = getState().sites.rangeErrors ? getState().sites.rangeErrors : [];
        if (val)
            rangeErrors = [{ ...rangeErrors, field: "noChanges", error: labels.custom_noumenon_list.no_range_changes }]
        else if (field) {
            rangeErrors = rangeErrors.filter((ce) => ce.field != field)
        }
        else {
            rangeErrors = [];
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                rangeErrors: rangeErrors
            }
        })
    }
}

export function editRubricForm(event, recId) {
    return (dispatch, getState) => {
        let currentRubric = getState().sites.currentRubric
        let key = event.target.id
        let value = event.target.value
        if (getState().sites.currentRubric && getState().sites.currentRubric[key] != value) {
            currentRubric = getState().sites.currentRubric ? { ...getState().sites.currentRubric, [key]: value } : { [key]: value, noumenonCode: '', noumenonName: '' }
        }
        else {
            currentRubric = getState().sites.currentRubric
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                currentRubric
            }
        })
        dispatch(showNoFormChangesErrorRubric(false))
    }

}

export function saveRubric(history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true));
        let currentRubric = getState().sites && getState().sites.currentRubric ? getState().sites && getState().sites.currentRubric : ''
        if (currentRubric) {
            let req = requiredFieldsForRubric.filter(rf => (!currentRubric[rf]))
            if (req && req.length) {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        rubricErrors: req.map(r => { return { field: `${r}`, error: `${requiredFieldTextForRubric[r]} ${labels.required_label}` } }),
                        currentRubric: {
                            noumenonId: getState().sites && getState().sites.noumenonId ? getState().sites.noumenonId : 0,
                            gender: currentRubric.gender,
                            phase: currentRubric.phase,
                            startingAge: currentRubric.startingAge,
                            endingAge: currentRubric.endingAge,
                            startingHeight: currentRubric.startingHeight,
                            endingHeight: currentRubric.endingHeight
                        },
                    }
                })
                dispatch(toggleLoading(false));
            }
            else {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        rubricErrors: [],
                    }
                })
                let siteId = getState().sites && getState().sites.currentSite && getState().sites.currentSite.id ? getState().sites.currentSite.id : '';
                let noumenonId = getState().sites && getState().sites.noumenonId ? getState().sites.noumenonId : 0;
                let gender = currentRubric.gender && currentRubric.gender == "all" ?  null : currentRubric.gender;
                let phase = currentRubric.phase ? currentRubric.phase : '';
                let startingAge = currentRubric.startingAge ? currentRubric.startingAge : '';
                let endingAge = currentRubric.endingAge ? currentRubric.endingAge : '';
                let startingHeight = currentRubric.startingHeight ? currentRubric.startingHeight : '';
                let endingHeight = currentRubric.endingHeight ? currentRubric.endingHeight : '';
                let params;
                if (currentRubric.id) {
                    params = { siteId: siteId, reportingNoumenonId: currentRubric.reportingNoumenonId, customDeterminantId: currentRubric.customDeterminantId, id: currentRubric.id, updateCustomDeterminantsDTO: { id: currentRubric.customDeterminantId, gender: gender, phase: phase, startingAge: startingAge ? parseInt(startingAge) : null, endingAge: endingAge ? parseInt(endingAge): null, startingHeight: startingHeight ? parseInt(startingHeight) : null, endingHeight: endingHeight ? parseInt(endingHeight) : null } };
                }
                else {
                    params = { siteId: siteId, reportingNoumenonId: noumenonId, createCustomDeterminantsDTO: { gender: gender, phase: phase, startingAge: startingAge ? parseInt(startingAge) : null, endingAge: endingAge ? parseInt(endingAge) : null, startingHeight:  startingHeight ? parseInt(startingHeight): null, endingHeight: endingHeight ? parseInt(endingHeight) : null } };
                }
                promisesList.push(axios[getState().sites.currentRubric.id ? 'put' : 'post'](getState().sites.currentRubric.id ? API_URL.UPDATE_CUSTOM_RUBRICS : API_URL.CREATE_CUSTOM_RUBRICS, { ...params }).then(response => {
                    // dispatch(toggleLoading(false));
                    history.push(`/sites/${siteId}/customizations/${currentRubric.noumenonCode}`)
                    let message
                    if (currentRubric.id) {
                        message = labels.custom_fields_labels.rubric_update
                    }
                    else {
                        message = labels.custom_fields_labels.rubric_save
                    }
                    setTimeout(() => {
                        dispatch(toggleNotification(true, constants.notification_type.success, '',
                            [`${labels.custom_fields_labels.rubric_for + " " + currentRubric.gender} ${message}`], true, 3000))
                    }, 2000);
                }).catch(error => {
                    dispatch(toggleLoading(false));
                    dispatch(handleErrors(error))
                }))
            }
        }
    }
}

export function editRubric(history, path) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        dispatch({
            type: SET_PROPS,
            payload: {
                currentRubric: {}
            }

        })
        dispatch(showNoFormChangesErrorRubric(false))
        history.push(path)

    }
}

export function getRubrics(id, siteId) {
    return (dispatch, getState) => {
        promisesList.push(axios.get(`${API_URL.GET_RUBRICS}?reportingNoumenonId=${id}&siteId=${siteId}&loadRubricRanges=true`).then(response => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    rubrics: response.data.value
                }
            })
        }).catch(errors => {
            dispatch(handleErrors(errors))
            dispatch(handleStatusCodes(errors))
        }))
    }
}

export function addRange(history, path, code, rubric_id) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        dispatch(toggleNotification(false))
        let rubric;
        promisesList.push(axios.get(`${API_URL.GET_INDIVIDUAL_RUBRIC}?id=${rubric_id}`).then(response => {
            // dispatch(toggleLoading(false))
            rubric = response.data.value
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentRange: {
                        noumenonCode: code,
                        customRubricId: rubric_id,
                        gender: rubric.gender,
                        type: "",
                        gradePoints: "",
                        low: "",
                        high: "",
                    },
                    initialCurrentRange: {
                        type: "",
                        gradePoints: "",
                        low: "",
                        high: "",
                    }
                }
            })
        }).catch(errors => {
            dispatch(handleErrors(errors))
            dispatch(handleStatusCodes(errors))
        }))
        dispatch(showNoFormChangesErrorRange(false))
        history.push(path)
    }
}

export function editRange(history, path) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        dispatch(showNoFormChangesErrorRange(false))
        dispatch({
            type: SET_PROPS,
            payload: {
                currentRange: {}
            }
        })
        history.push(path)
    }
}

export function setCurrentRange(code, rubric_id, siteId, history, range_id) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let range;
        let rubric;
        promisesList.push(axios.get(`${API_URL.GET_INDIVIDUAL_RUBRIC}?id=${rubric_id}`).then(response => {
            rubric = response.data.value
            if (range_id) {
                dispatch(setCurrentSiteDetails(siteId, 'range_form_edit', history))
                promisesList.push(axios.get(`${API_URL.GET_RANGE}?rubricRangeId=${range_id}`).then(response => {
                    range = response.data.value
                    dispatch({
                        type: SET_PROPS,
                        payload: {
                            currentRange: {
                                noumenonCode: code,
                                customRubricId: rubric_id,
                                id: range_id,
                                gender: rubric.gender,
                                type: range.type,
                                gradePoints: range.gradePoints == 0 ?  '0.0' : range.gradePoints ? parseFloat(range.gradePoints).toFixed(1): "",
                                low: range.low,
                                high: range.high,
                            },
                            initialCurrentRange: {
                                type: range.type,
                                gradePoints: range.gradePoints == 0 ? '0.0' : range.gradePoints ? parseFloat(range.gradePoints).toFixed(1): "",
                                low: range.low,
                                high: range.high,
                            }
                        }
                    })
                }).catch(errors => {
                    dispatch(handleErrors(errors))
                    dispatch(handleStatusCodes(errors))
                }))
            }
            else {
                dispatch(setCurrentSiteDetails(siteId, 'range_form_new', history))
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        currentRange: {
                            noumenonCode: code,
                            customRubricId: rubric_id,
                            gender: rubric.gender,
                            type: "",
                            gradePoints: "",
                            low: "",
                            high: "",
                        },
                        initialCurrentRange: {
                            type: "",
                            gradePoints: "",
                            low: "",
                            high: "",
                        }
                    }

                })
            }
        }).catch(errors => {
            dispatch(handleErrors(errors))
            dispatch(handleStatusCodes(errors))
        }))
    }
}

export function editRangeForm(event, recId) {
    return (dispatch, getState) => {
        let currentRange = getState().sites.currentRange
        let key = event.target.id
        let value = event.target.value
        if (getState().sites.currentRange && getState().sites.currentRange[key] != value) {
            currentRange = getState().sites.currentRange ? { ...getState().sites.currentRange, [key]: value } : { [key]: value, noumenonCode: '', noumenonName: '' }
        }
        else {
            currentRange = getState().sites.currentRange
        }
        dispatch({
            type: SET_PROPS,
            payload: {
                currentRange
            }
        })
        dispatch(showNoFormChangesErrorRange(false))
    }

}

export function saveRange(history) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true));
        let currentRange = getState().sites && getState().sites.currentRange ? getState().sites && getState().sites.currentRange : ''
        if (currentRange) {
            let req = requiredFieldsForRange.filter(rf => (!currentRange[rf]))
            if (req && req.length) {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        rangeErrors: req.map(r => { return { field: `${r}`, error: `${requiredFieldTextForRange[r]} ${labels.required_label}` } }),
                        currentRange: {
                            noumenonId: getState().sites && getState().sites.noumenonId ? getState().sites.noumenonId : 0,
                            customRubricId: currentRange.customRubricId,
                            id: currentRange.id ? currentRange.id : '',
                            gender: currentRange.gender,
                            type: currentRange.type,
                            gradePoints: currentRange.gradePoints,
                            low: currentRange.low,
                            high: currentRange.high,
                        },
                    }
                })
                dispatch(toggleLoading(false));
            }
            else {
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        rubricErrors: [],
                    }
                })
                let siteId = getState().sites && getState().sites.currentSite && getState().sites.currentSite.id ? getState().sites.currentSite.id : '';
                let type = currentRange.type ? currentRange.type : '';
                let gradePoints = currentRange.gradePoints ? currentRange.gradePoints : "";
                let low = currentRange.low ? currentRange.low : '';
                let high = currentRange.high ? currentRange.high : '';
                let rubric_id = currentRange.customRubricId ? currentRange.customRubricId : '';
                let params;
                if (currentRange.id) {
                    params = { customRubricId: rubric_id, id: currentRange.id, type: type, gradePoints: gradePoints ? parseFloat(gradePoints).toFixed(1) : null, low: low ? parseFloat(low): null, high:  high ? parseFloat(high): null };
                }
                else {
                    params = { customRubricId: rubric_id, type: type, gradePoints: gradePoints ? parseFloat(gradePoints).toFixed(1) : null, low: low ? parseFloat(low) : null, high: high ? parseFloat(high) : null };
                }
                promisesList.push(axios[getState().sites.currentRange.id ? 'put' : 'post'](getState().sites.currentRange.id ? API_URL.UPDATE_CUSTOM_RANGES : API_URL.CREATE_CUSTOM_RANGES, { ...params }).then(response => {
                    dispatch(toggleLoading(false));
                    history.push(`/sites/${siteId}/customizations/${currentRange.noumenonCode}`)
                    let message
                    if (currentRange.id) {
                        message = labels.custom_fields_labels.range_update
                    }
                    else {
                        message = labels.custom_fields_labels.range_save
                    }
                    setTimeout(() => {
                        dispatch(toggleNotification(true, constants.notification_type.success, '',
                            [`${labels.custom_fields_labels.range_for + " " + constants.type.find(g => g.value == currentRange.type).text} ${message}`], true, 3000))
                    }, 2000);
                }).catch(error => {
                    dispatch(toggleLoading(false));
                    dispatch(handleErrors(error))
                }))
            }
        }
    }
}



export function toggleRubricModal(modalMode, rubric_id, range_id) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                modalMode: modalMode ? modalMode : ''
            }
        })
        dispatch({
            type: SET_PROPS,
            payload: {
                isRubricModal: getState() && getState().sites ? !getState().sites.isRubricModal : false,
                deleteRubricId: rubric_id ? rubric_id : '',
                deleteRangeId: range_id ? range_id : '',
                deleteModal: modalMode
            }
        })
    }
}

export function deleteRubricDetails(history, modal, rubric_id, range_id) {
    return (dispatch, getState) => {
        if (modal == "deleteRubric") {
            dispatch(deleteRubric(history, rubric_id))
        }
        if (modal == "deleteRange") {
            dispatch(deleteRange(history, rubric_id, range_id))
        }
    }
}

export function deleteRubric(history, rubric_id) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let rubric = getState().sites.rubrics && getState().sites.rubrics.find((rubric) => rubric.id == rubric_id)
        let gender = rubric.gender ? rubric.gender : ""
        promisesList.push(axios.delete(`${API_URL.DELETE_RUBRICS}?id=${rubric_id}`).then(response => {
            dispatch(toggleLoading(false))
            dispatch(toggleRubricModal())
            dispatch(getNoumenonList(getState().sites.currentSite.id, getState().sites.currentCustomNoumenon.noumenonCode, history, 'details'))
            dispatch(toggleNotification(true, constants.notification_type.success, '',
                [`${gender} ${labels.custom_fields_labels.rubric_delete}`], false, 3000))
        }).catch(errors => {
            dispatch(handleErrors(errors))
        }))
    }
}

export function deleteRange(history, rubric_id, range_id) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        let range = getState().sites.rubrics && getState().sites.rubrics.find((rubric) => rubric.id == rubric_id).customRubricRangesDTO.find((range) => range.id == range_id)
        let type = range.type ? range.type : ""
        promisesList.push(axios.delete(`${API_URL.DELETE_RANGE}?id=${range_id}`).then(response => {
            dispatch(toggleLoading(false))
            dispatch(toggleRubricModal())
            dispatch(getNoumenonList(getState().sites.currentSite.id, getState().sites.currentCustomNoumenon.noumenonCode, history, 'details'))
            dispatch(toggleNotification(true, constants.notification_type.success, '',
                [`${constants.type.find(g => g.value == type).text} ${labels.custom_fields_labels.range_delete}`], false, 3000))
        }).catch(errors => {
            dispatch(handleErrors(errors))
        }))
    }
}

export function GetAllNoumenonNames(){
    return (dispatch) => {
      promisesList.push(axios.get(`${API_URL.GET_ALL_NOUMENON_NAMES}`).then(response => {
            dispatch({
              type: SET_PROPS,
              payload: {
                  allNoumenonNameList: response.data,
              }
          })
      }).catch(error => {
          dispatch(handleErrors(error))
      }))
    }
}
export function getSitesList() {
    return (dispatch) => {
        dispatch(toggleLoading(true))
        if (axios.defaults && axios.defaults.headers && !axios.defaults.headers.Authorization) {
            axios.defaults.headers[constants.authorization] = `${constants.bearer} ${localStorage.getItem(constants.accessToken)}`
        }
        promisesList.push(axios.get(API_URL.SITES_NAMES).then((sites) => {
            dispatch({
                type: SET_PROPS,
                payload: {
                    sitesList: sites.data
                }
            })
            // dispatch(handleLoading())
        }).catch((error) => {
            dispatch(handleErrors(error))
        }))
    }
}

export function setNoumenonFields() {
    return (dispatch) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateNoumenonList: [
                    {
                        code: '',
                        startDate: '',
                        endDate: '', 
                        uuid: setUuid(),
                    }
                ]
            }
        })
    }
}

export function addNoumenonFields() {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateNoumenonList: [
                    ...getState().sites.CreateNoumenonList,
                    {
                        code: '',
                        startDate: '',
                        endDate: '', 
                        uuid: setUuid()
                    }
                ]
            }
        })
    }
}

export function removeNoumenonFields(uuid) {
    return (dispatch, getState) => {
        let CreateNoumenonList = getState().sites.CreateNoumenonList
        CreateNoumenonList = CreateNoumenonList.filter((cf) => cf.uuid != uuid)
        CreateNoumenonList = [
            ...CreateNoumenonList
        ]
        let currentSite = getState().sites.currentSite 
        currentSite.noumenonCodes = CreateNoumenonList

        dispatch({
            type: SET_PROPS,
            payload: {
                CreateNoumenonList: CreateNoumenonList
            }
        })
    }
}

export function editNoumenon(index, eve, uuid) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false));
        let CreateNoumenonList = [...getState().sites.CreateNoumenonList];
        let nouemonListLength = CreateNoumenonList.length
        let updatedNoumenon = {
            ...CreateNoumenonList[index],
            [eve.target.id]: eve.target.value
        };
        CreateNoumenonList[index] = updatedNoumenon;
        let currentSite = getState().sites.currentSite 
        if (CreateNoumenonList.length == 1 && ((CreateNoumenonList[0].code == '0' || CreateNoumenonList[0].code == '') &&  (!CreateNoumenonList[0].startDate) && (!CreateNoumenonList[0].endDate))){
            currentSite.noumenonCodes = []
        } else{
            currentSite.noumenonCodes = CreateNoumenonList
        }
       
        dispatch({
            type: SET_PROPS,
            payload: {
                CreateNoumenonList: CreateNoumenonList,
                currentSite: currentSite
            }
        });
        if (eve.target.id == 'code' &&  nouemonListLength == index + 1  )
            dispatch(addNoumenonFields())
    };
}

export function customReferenceRangesListPage(history, sites) {
    return (dispatch, getState) => {
        dispatch({
            type: SET_PROPS,
            payload: {
                currentUser: { siteId: sites.id }
            }
        })
        history.push(`/sites/${getState().sites.currentSite.id}/custom_reference_ranges`)
    }
}

export function getCustomReferenceRanges(siteId) {
    return (dispatch, getState) => {
        dispatch(toggleLoading(true))
        promisesList.push(axios.get(`${API_URL.CUSTOM_NOUMENON_LIST}?siteId=${siteId}`).then(response => {
            dispatch(toggleLoading(false))
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentSiteCustomNoumenonList: response.data
                }
            })
        }).catch(errors => {
            dispatch(handleErrors(errors))
            dispatch(handleStatusCodes(errors))

        }))
    }
}

export function addCustomReferenceRanges(history) {
    return (dispatch, getState) => {
        dispatch(toggleNotification(false))
        var currentSite = getState().sites.currentSite
        dispatch({
            type: SET_PROPS,
            payload: {
                currentCustomReferenceRange: {
                    panelName: "",
                    PanelId: "",
                    noumenonCode: "",
                    noumenonName: "",
                    units: "",
                    gender: ""
                },
                unitsList: []
            }

        })
        history.push(`/sites/${currentSite.id}/custom_reference_ranges/new`)
    }
}

export function editCustomReferenceRange(history, path) {
    return (dispatch) => {
        dispatch(toggleNotification(false))
        dispatch(showNoFormChangesError(false))
        dispatch({
            type: SET_PROPS,
            payload: {
                currentCustomReferenceRange: {}
            }
        })
        history.push(path)
    }
}

export function detailsCustomReferenceRange(history, path) {
    return (dispatch) => {
        dispatch(toggleNotification(false))
        dispatch(showNoFormChangesError(false))
        dispatch({
            type: SET_PROPS,
            payload: {
                currentCustomReferenceRange: {}
            }
        })
        history.push(path)
    }
}
export function editCustomReferenceRangeForm(event, recId) {
    return (dispatch, getState) => {
        let currentCustomReferenceRange = getState().sites.currentCustomReferenceRange
        let noumenonListWithValueFormat = getState().sites.noumenonListWithValueFormat
        let unitsList = getState().sites.unitsList ? getState().sites.unitsList : []
        let customeNoumenon;
        if (recId == 'panel') {
            if (event != null && getState().sites.currentCustomReferenceRange && getState().sites.currentCustomReferenceRange.noumenonName != event.value) {
                currentCustomReferenceRange = { ...getState().sites.currentCustomReferenceRange, panelName: event.name }
                if (event.id) {
                    currentCustomReferenceRange = { ...currentCustomReferenceRange, PanelId: event.id }
                }
                dispatch(showNoFormChangesError(false, 'panel'));
            }
        }
        else if (recId == 'noumenonCode') {
            if (event != null && getState().sites.currentCustomReferenceRange && getState().sites.currentCustomReferenceRange.noumenonName != event.value) {
                currentCustomReferenceRange = { ...getState().sites.currentCustomReferenceRange, noumenonName: event.value, units: '' }
                if (event.code) {
                    currentCustomReferenceRange = { ...currentCustomReferenceRange, noumenonCode: event.code }
                }
                customeNoumenon = noumenonListWithValueFormat.find((noumenon) => noumenon.noumenonName == event.value)
                if (customeNoumenon && customeNoumenon.valueFormats) {
                    unitsList = customeNoumenon.valueFormats.map((unit) => { return { value: unit } })
                }
                else {
                    unitsList = []
                }
                dispatch(showNoFormChangesError(false, 'noumenonName'));
            }
        }
        else {
            let key = event.target.id
            let value = event.target.value
            if (getState().sites.currentCustomReferenceRange && getState().sites.currentCustomReferenceRange[key] != value) {
                currentCustomReferenceRange = getState().sites.currentCustomReferenceRange ? { ...getState().sites.currentCustomReferenceRange, [key]: value } : { [key]: value, noumenonCode: '', noumenonName: '' }
            }
            else {
                currentCustomReferenceRange = getState().sites.currentCustomReferenceRange
            }

        }
        dispatch({
            type: SET_PROPS,
            payload: {
                currentCustomReferenceRange,
                unitsList: unitsList
            }
        })
        if (currentCustomReferenceRange.units != '' && parseInt(currentCustomReferenceRange.units) != 0)
            dispatch(showNoFormChangesError(false, 'units'));
        if (currentCustomReferenceRange.noumenonName != '')
            dispatch(showNoFormChangesError(false, 'noumenonName'));
        dispatch(showNoFormChangesError(false))
    }

}

export function setcurrentCustomReferenceRange(siteId, code, action) {
    return (dispatch, getState) => {
        let unitsList;
        let noumenonName;
        let noumenonNameList = getState().sites.noumenonNameList ? getState().sites.noumenonNameList : []
        let noumenonListWithValueFormat = getState().sites.noumenonListWithValueFormat ? getState().sites.noumenonListWithValueFormat : []
        if (code) {
            promisesList.push(axios.get(`${API_URL.GET_CUSTOM_NOUMENA}?siteId=${siteId}&code=${code}`).then(response => {
                let noumenonCode = response.data.value.noumenonCode
                noumenonName = noumenonNameList.length > 0 ? noumenonNameList.find((noumenon) => noumenon.code == noumenonCode).value : ""
                let units = response.data.value.units
                let customeNoumenon = noumenonListWithValueFormat.find((noumenon) => noumenon.code == noumenonCode)
                if (customeNoumenon && customeNoumenon.valueFormats) {
                    unitsList = customeNoumenon.valueFormats.map((unit) => { return { value: unit } })
                }
                else {
                    unitsList = []
                }
                dispatch({
                    type: SET_PROPS,
                    payload: {
                        currentCustomReferenceRange: {
                            panelName: "",
                            panelId: "",
                            noumenonCode: noumenonCode,
                            noumenonName: noumenonName,
                            units: units,
                            id: response.data.value.id,
                            gender: response.data.value.gender ? response.data.value.gender : ""
                        },
                        initialcurrentCustomReferenceRange: {
                            panelName: "",
                            panelId: "",
                            noumenonCode: noumenonCode,
                            noumenonName: noumenonName,
                            units: units,
                            gender: response.data.value.gender ? response.data.value.gender : ""
                        },
                        unitsList: unitsList,
                        isNoumenonModal: false,
                    }
                })

                dispatch(toggleLoading(false))   
                dispatch(showNoFormChangesError(false));
            }).catch(errors => {
                dispatch(handleErrors(errors))
                dispatch(toggleLoading(false))
            }))
        }
        else {
            dispatch({
                type: SET_PROPS,
                payload: {
                    currentCustomReferenceRange: {
                        panelName: "",
                        panelId: "",
                        noumenonCode: "",
                        noumenonName: "",
                        units: "",
                        gender: ""
                    },
                    initialcurrentCustomReferenceRange: {
                        panelName: "",
                        panelId: "",
                        noumenonCode: "",
                        noumenonName: "",
                        units: "",
                        gender: ""
                    }
                }
            })
            dispatch(toggleLoading(false))
        }
    }
}

export const actionCreators = {
    getSites,
    setCurrentSite,
    editSite,
    filterSites,
    addSite,
    saveSite,
    siteTransaction,
    logoUploaded,
    setCurrentSiteDetails,
    clearFilteredSites,
    navigateSite,
    getPanels,
    editPanels,
    screenSize,
    downloadAttendeeList,
    GetReferralSites,
    removePic,
    setSiteDocuments,
    postFile,
    siteDocumentInput,
    uploadDocument,
    handlePublicationDate,
    getDocumentsWithSiteId,
    getDocument,
    downloadSiteDocument,
    filterSitesList,
    setSiteSearchParam,
    populateSitesSearchParams,
    clearSiteNames,
    setSiteNameList,
    clearSiteNamesOnClick,
    downloadSitesList,
    setDocumentsFields,
    addDocumentFields,
    removeDocumentFields,
    postDocumentFile,
    handlePublicationDateDocumentFile,
    handleTitleDocumentFile,
    uploadDocumentList,
    clearDocumentsFields,
    showImage,
    hideImage,
    customNoumenonListPage,
    getCustomNoumenonList,
    addCustomNoumenon,
    editCustomNoumenon,
    setCurrentCustomNoumenon,
    saveCustomNoumenon,
    editCustomNoumenonForm,
    getNoumenonList,
    showNoFormChangesError,
    detailsCustomNoumenon,
    deleteCustomNoumenon,
    toggleNoumenonModal,
    deleteDocument,
    addRubric,
    setCurrentRubric,
    editRubric,
    addRange,
    setCurrentRange,
    editRange,
    toggleRubricModal,
    deleteRubricDetails,
    deleteRubric,
    deleteRange,
    saveRubric,
    editRubricForm,
    getRubrics,
    showNoFormChangesErrorRubric,
    editRangeForm,
    saveRange,
    showNoFormChangesErrorRange,
    GetAllNoumenonNames,
    getSitesList,
    setNoumenonFields,
    addNoumenonFields,
    removeNoumenonFields,
    editNoumenon,
    customReferenceRangesListPage,
    getCustomReferenceRanges,
    addCustomReferenceRanges,
    editCustomReferenceRange,
    editCustomReferenceRangeForm,
    detailsCustomReferenceRange,
    setcurrentCustomReferenceRange
}