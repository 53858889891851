import React from 'react';
import ReactHighcharts from 'react-highcharts';
import { Link } from 'react-router-dom';
import { Chart } from "react-google-charts";
import labels from '../../../config/localization';
class NoumenaCharts extends React.Component {
    constructor(props) {
        super(props);
    }
    scatterConfig(data) {
        const config = {
            chart: {
                type: 'scatter',
                height: 200,
                animation: false,
            },
            yAxis: {
                title: {
                    enabled: false,
                }
            },
            xAxis: {
                tickPositions: [20, 30, 40, 50, 60, 70, 80, 90, 100],
                minTickInterval: 10,
                startOnTick: true,
                endOnTick: true
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            plotOptions: {
                scatter: {
                    marker: {
                        radius: 2.5,
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)'
                            }
                        }
                    },
                    states: {
                        hover: {
                            marker: {
                                enabled: false
                            }
                        }
                    },
                    tooltip: {
                        headerFormat: '<b>{series.name}</b><br>',
                        pointFormat: '{point.x}, {point.y}'
                    }
                },
                series: {
                    animation: false,
                    cursor: 'pointer',
                    turboThreshold:0,
                    events: {
                        click: function (event) {
                            data.history.push(`/visits/${event.point.visitId}`)
                            // window.open(window.location.origin + '/visits/' + event.point.visitId)
                        }
                    }
                }
            },
            series: [{
                name: labels.noumena_labels.female,
                color: '#f99deb',
                data: this.props.femaleData.filter(age => age.y !== 0),
                animation: false,
                marker: {
                    symbol: 'circle'
                }
            }, {
                name: labels.noumena_labels.male,
                color: '#008FBC',
                data: this.props.maleData.filter(age => age.y !== 0)
            }]
        }
        return config;
    };
    getPieChartConfig(data) {
        const config = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                height: 200,
                animation: false,
            },
            yAxis: {
                title: {
                    enabled: false,
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            tooltip: {
                pointFormat: '{point.percentage:.1f} %'
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: false,
                        format: '<b>{point.name}</b>:<br>{point.percentage:.1f} %<br>total: {point.total}',
                    }
                },
                series: {
                    animation: false
                }
            },
            series: [{
                colorByPoint: true,
                data: data
            }]
        }
        return config;
    }

    getColumnChartConfig(data, scale) {
        for (let i = 0; i < data.x.length - 1; i++) {
            for (let j = 0; j < data.x.length - i - 1; j++) {
                if (data.x[j] > data.x[j + 1]) {
                    //Sort data.x
                    let temp1 = data.x[j];
                    data.x[j] = data.x[j + 1];
                    data.x[j + 1] = temp1;

                    //Sort data.y
                    let temp2 = data.y[j];
                    data.y[j] = data.y[j + 1];
                    data.y[j + 1] = temp2;
                }
            }
        }
        const config = {
            chart: {
                type: 'column',
                height: 200,
                width: 247,
                animation: false,
            },
            tooltip: {
                formatter: function () {
                    return 'The count for <b>' + this.x +
                        '</b> is <b>' + this.y + '</b>';
                }
            },
            yAxis: {
                title: {
                    enabled: false,
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: data.x
            },

            plotOptions: {
                series: {
                    animation: false,
                    pointWidth: 180 / data.x.length,
                    dataLabels: {
                        enabled: true
                    },
                    animation: false
                }
            },

            series: [{
                data: data.y,
                color: '#000000'
            }]
        }
        return config;
    }

    viewNoumena(chart) {
        let query = `/noumena/${this.props.code}/${chart}`
        if (this.props.currentNoumenaFilters && this.props.currentNoumenaFilters.MaxDeviations !== undefined) {
            query += `?max_deviations=${this.props.currentNoumenaFilters.MaxDeviations}`
        }
        this.props.history.push(query)
    }
    shouldComponentUpdate(nextProps) {
        if (JSON.stringify(this.props.currentNoumenonNames) != JSON.stringify(nextProps.currentNoumenonNames)) {
            return false;
        }
    }

    render() {
        return (
            this.props.type == "VADR" && this.props.zeroCount == 0 ? ' ' :
                <div className='noumenon-container container'>
                    <div className='heading'>
                        <Link to={`/data/${this.props.code}`} style={{ fontSize: '2em', color: '#008FBC' }}>{this.props.noumenonName}</Link>
                    </div>
                    <a onClick={() => this.props.downloadCSVNoumena(this.props.code)} style={{ fontSize: '1em', color: '#008FBC' }}>{labels.noumena_labels.download_excel}</a>
                    <div id="container">
                    </div>
                    {this.props.graphDisplayCheck.filter(s => s).length > 0 ?
                        <div className='row'>
                            {_.head(this.props.age) == 0 && this.props.valueType == 'NM' ? '' :
                                <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'
                                    onClick={() => this.viewNoumena('cloud.svg')}>
                                    {this.props.valueType != 'NM' ? <ReactHighcharts config={this.getPieChartConfig(this.props.pieGraphData)} ></ReactHighcharts> :
                                        _.head(this.props.age) != 0 ? <ReactHighcharts config={this.scatterConfig(this.props)}>
                                        </ReactHighcharts> : ''}
                                </div>}
                            {/* {this.props.type!="VADR" ?  */}
                            <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'
                                onClick={() => this.viewNoumena('histogram.svg')}>
                                {this.props.valueType != 'NM' ?
                                    <ReactHighcharts config={this.getColumnChartConfig(this.props.textValueTypeBarValues)}>
                                    </ReactHighcharts> :
                                    /* <Chart
                                        chartType="Histogram"
                                        data={[[" "]].concat(this.props.histogramData)}
                                        width="300"
                                        height="162"
                                        options={{
                                            legend: { position: 'none' },
                                            colors: ['black'],
                                            histogram: {
                                                hideBucketItems: true
                                            }
                                        }}
                                    /> */
                                    <ReactHighcharts config={this.getColumnChartConfig(this.props.histVADRpresentor, this.props.scale)}>
                                    </ReactHighcharts>
                                }
                            </div>
                            {/* :<ReactHighcharts config={this.getColumnChartConfig(this.props.histVADRpresentor)}>
                        </ReactHighcharts>} */}
                            {_.head(this.props.middleGraphData.x) != "N/A" ?
                                <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'
                                    onClick={() => this.viewNoumena('points_histogram.svg')}>
                                    <ReactHighcharts config={this.getColumnChartConfig(this.props.middleGraphData)}>
                                    </ReactHighcharts>
                                </div> : ''}
                        </div> : <div className='jumbotron'>
                            <h1>{labels.noumena_labels.no_values_to_plot}</h1>
                        </div>}
                </div>
        )
    }
}

export default NoumenaCharts;