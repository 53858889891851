import React, { Component } from 'react';
import FormControl from '../../../components/FormControls'
import labels from '../../../config/localization';
import { Link } from 'react-router-dom';
import providerDefault from '../../../assets/images/provider-default.png';
import constants from '../../../config/constants';
import LoadingTemplate from '../../../components/LoadingTemplate';
import PageTitle from '../../../components/PageTitle';
import _ from 'lodash';
import moment from 'moment';
import { getDateFormat } from '../../AnalyticsBeta/tools/helpers';

class SitesDetails extends Component {

    componentDidMount() {
        let storedRole = localStorage.getItem('role') ? constants.logged_roles[localStorage.getItem('role')] : null
        let role = this.props.loggedInUser ? this.props.loggedInUser.role : storedRole
        if (role && role == constants.logged_roles.CG) {
            this.props.history.push('/patients/list')
        }
        else {
            this.props.toggleNotification(false)
            if (this.props.match.params && this.props.match.params.siteId) {
                this.props.setCurrentSiteDetails(this.props.match.params.siteId)
            }
        }
    }

    getStrongDate = (date) =>  {
        return <strong>{(moment(date).format(`${getDateFormat(this.props.currentSite.datePreference)}`))}</strong>
    }
    render() {
        return (
            this.props.isLoading ? <LoadingTemplate isDetails={true} haveSidebar={true} /> :
                <>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 data-section site-details p-0'>
                        <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 form-group site-details-btn-section order-2'>
                            <FormControl type='button' btnType='primary' text={labels.sites_labels.edit_site} onClick={() => this.props.navigateSite(this.props.history, `/sites/edit/${this.props.currentSite.id}`)} />
                            <FormControl type='button' btnType='default' text={labels.sites_labels.add_provider}
                                disabled={this.props.loggedInUser && (this.props.loggedInUser.role == constants.logged_roles.AN) ? ((this.props.currentSite.subscriptionId && this.props.currentSite.billingStrategy == 'recurring')) ? true : false : true}
                                onClick={() => this.props.addSiteProvider(this.props.history, [this.props.currentSite])}
                                help={this.props.currentSite.subscriptionId && this.props.currentSite.billingStrategy == 'recurring' ?
                                    'Add provider from the users page or click on add user button in the below as the site is associated to reculry' : ''}
                            />
                            <FormControl type='button' btnType='default' text={labels.sites_labels.add_caregiver}
                                onClick={() => this.props.addSiteCaregiver(this.props.history, [this.props.currentSite])}
                            />
                            
                            <FormControl type='button' btnType='default' text={labels.sites_labels.add_user}
                                disabled={this.props.loggedInUser && (this.props.loggedInUser.role == constants.logged_roles.AN) ? false : true}
                                onClick={() => this.props.addUserSites(this.props.history, this.props.currentSite)} />
                            <FormControl type='button' btnType='default' text={labels.sites_labels.add_elk}
                                disabled={this.props.loggedInUser && (this.props.loggedInUser.role == constants.logged_roles.AN) ? false : true}
                                onClick={() => this.props.addCreditTransaction(this.props.history, this.props.currentSite)} />
                            { this.props.currentSite.planType != constants.planTypeBasic ?
                                <FormControl type='button' btnType='default' text={labels.custom_noumenon_list.customize_noumenon}
                                    disabled={this.props.loggedInUser && (this.props.loggedInUser.role == constants.logged_roles.AN) ? false : true}
                                    onClick={() => this.props.customNoumenonListPage(this.props.history, [this.props.currentSite])} />
                            : null }
                            <FormControl type='button' btnType='default' text={labels.sites_labels.custom_reference_ranges}
                                disabled={this.props.loggedInUser && (this.props.loggedInUser.role == constants.logged_roles.AN) ? false : true}
                                onClick={() => this.props.customReferenceRangesListPage(this.props.history, [this.props.currentSite])} />
                            {/* {this.props.currentSite && this.props.currentSite.category == constants.conference_category ? */}
                            <FormControl type='button' className='mt-4' btnType='info' text={labels.sites_labels.download_attendee}
                                onClick={() => this.props.downloadAttendeeList()} />
                            {/* : null} */}
                        </div>
                        <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 site-details-main-sec order-1 p-0'>
                            <div className='card react-bootstrap-table'>
                                {this.props.currentSite ? <div className='site-brief-details p-4'>
                                    <div className='margin-auto row'>
                                        <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                            <legend>{labels.sites_labels.contact_info}</legend>
                                            {this.props.currentSite.address ? <p>{this.props.currentSite.address},</p> : null}
                                            <p>{this.props.currentSite.city ? `${this.props.currentSite.city}, ` : ''}{this.props.currentSite.state} {this.props.currentSite.postalCode} </p>
                                            {this.props.currentSite.country && this.props.currentSite.country != 0 ? <p>{this.props.currentSite.country}</p> : null}
                                            {this.props.currentSite.phone ? <p><strong>{labels.sites_labels.phone}: </strong>{this.props.currentSite.phone}</p> : null}
                                        </div>
                                        <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                            <legend>MFA</legend>
                                            { process.env.REACT_APP_MFA_ENABLED != 'false' ?
                                                <h3 className='text-center'>{this.props.currentSite.mfaRequired ? `Enabled`: 'Disabled'}</h3>
                                            : "" }    
                                        </div>
                                    </div>    

                                    <div className='margin-auto row'>      
                                        <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-5'>
                                            <legend>{labels.sites_labels.logo_label}</legend>
                                            <div className='text-center'>
                                                {this.props.currentSite.logoFile
                                                    ? <img className='site-logo-sec'
                                                        src={`data:${this.props.currentSite.logoContentType};base64,${this.props.currentSite.logoFile}`}
                                                        alt={this.props.currentSite.logoFileName}
                                                    /> : labels.sites_labels.not_uploaded}
                                            </div>
                                        </div>
                                                  
                                        <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                            <legend>{labels.sites_labels.banner_logo_label}</legend>
                                            <div className='text-center'>
                                                {this.props.currentSite.bannerLogoFile
                                                    ? <img className='site-banner-logo-sec'
                                                        src={`data:${this.props.currentSite.bannerLogoContentType};base64,${this.props.currentSite.bannerLogoFile}`}
                                                        alt={this.props.currentSite.bannerLogoFileName}
                                                    />
                                                    : labels.sites_labels.not_uploaded}
                                            </div>
                                        </div>
                                    </div>

                                    <div className='margin-auto row'>
                                        <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>
                                            <legend>{labels.sites_labels.billing_label}</legend>
                                            <h3 className='text-center'>{this.props.currentSite.billingStrategy}</h3>
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>
                                            <legend>{labels.sites_labels.credits_label}</legend>
                                            <div className='text-center'>
                                                <h3>{this.props.currentSite.credits}</h3>
                                                {this.props.loggedInUser && this.props.loggedInUser.role == constants.logged_roles.AN ?
                                                    <a href='javascript:void(0)' className='transaction-history'
                                                        onClick={() => this.props.siteTransaction(this.props.currentSite.id, this.props.history)} >
                                                        {labels.sites_labels.transaction_history}</a> :
                                                    <a href='javascript:void(0)' className='transaction-history muted' >
                                                        {labels.sites_labels.transaction_history}</a>}
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-sm-12 col-xs-12'>
                                            <legend>{labels.sites_labels.accept_referral_label}</legend>
                                            <h3 className='text-center'>{this.props.currentSite.acceptsReferrals && parseInt(this.props.currentSite.acceptsReferrals) ? labels.noumena_labels.yes : labels.noumena_labels.no}</h3>
                                        </div>
                                    </div>
                                </div> : null}
                            </div>
                            <div className='row providers-users-sec react-bootstrap-table'>
                                {this.props.currentSite && this.props.currentSite.providers ?
                                    <React.Fragment>
                                        <div className='card col-lg-6'>
                                            <legend>{labels.sites_labels.providers_label}</legend>
                                            {this.props.currentSite && this.props.currentSite.providers ? this.props.currentSite.providers.map(sp => (
                                                <tr>
                                                    <span className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                                        <img className='provider-avatar'
                                                            alt={`${sp.name}-avatar`}
                                                            src={sp
                                                                && sp.value && sp.value.content
                                                                ? `data:${sp.value.contentType};base64,${sp.value.content}`
                                                                : providerDefault} onClick={() => this.props.history.push(`/providers/${sp.id}`)} />
                                                    </span>
                                                    <span className='col-lg-6 col-md-6 col-sm-12 col-xs-12 site-provider-name align-items-center'>
                                                        {this.props.loggedInUser.role == constants.logged_roles.AN ? <React.Fragment>
                                                            <a onClick={(e) => { e.stopPropagation(); this.props.history.push(`/providers/${sp.id}`) }}>{sp.name} <i className='fa fa-pencil' onClick={(e) => { e.stopPropagation(); this.props.navigateEditProvider(this.props.history, `/providers/edit/${sp.id}`) }}></i></a>
                                                        </React.Fragment> : <a className='muted'>{sp.name}</a>}
                                                    </span>
                                                </tr>

                                            )) : null}
                                        </div>
                                    </React.Fragment>
                                    : <div className='card  card-default col-lg-6'>
                                        <legend>{labels.sites_labels.providers_label}</legend>
                                        <div className='card-body'>
                                            <h5> {labels.none}</h5>
                                        </div>
                                    </div>}
                                {this.props.currentSite && this.props.currentSite.users ? <React.Fragment>
                                    <div className='card col-lg-6'>
                                        <legend>{labels.sites_labels.users_label}</legend>
                                        <tr>
                                            <th className='col-lg-6 col-md-6 col-sm-12 col-xs-12 no-wrap'>{labels.sites_labels.name_label}</th>
                                            <th className='col-lg-6 col-md-6 col-sm-12 col-xs-12 no-wrap'>{labels.sites_labels.lastseen}</th>
                                        </tr>
                                        {this.props.currentSite && this.props.currentSite.users ? this.props.currentSite.users.map(sp => (
                                            <tr>
                                                <span className='col-lg-6 col-md-6 col-sm-12 col-xs-12 site-provider-name'>
                                                    {this.props.loggedInUser.role == constants.logged_roles.AN ? <React.Fragment>
                                                        <span><Link to={`/users/${sp.id}`}>{sp.name}</Link> {!sp.isActive ? <span class="badge badge-warning">Inactive</span> : ''}
                                                        </span>
                                                    </React.Fragment> : <span><a className='muted'>{sp.name}</a> {!sp.isActive ? <span class="badge badge-warning">Inactive</span> : ''}</span>}
                                                </span>

                                                <span className='col-lg-6 col-md-6 col-sm-12 col-xs-12'>
                                                    <p>{sp.value}</p>
                                                </span>
                                            </tr>
                                        )) : null}
                                    </div></React.Fragment> :
                                    <div className='card  card-default col-lg-6'>
                                        <legend>{labels.sites_labels.users_label}</legend>
                                        <div className='card-body'>
                                            <h5>{labels.noumena_labels.no} {labels.sites_labels.users_label}</h5>
                                        </div>
                                    </div>}
                            </div>
                            <div className='recent-activity card mt-2 mb-2 react-bootstrap-table'>
                                <legend>{labels.sites_labels.recent_activity}</legend>
                                <div className='col-lg-12'>
                                    <table className='table table-striped table-hover table-condensed'>
                                        <thead className='recent-activity-header'>
                                            <tr>
                                                <th>{labels.sites_labels.month_label}</th>
                                                <th>{labels.sites_labels.year_label}</th>
                                                <th>{labels.sites_labels.visits_label}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.props.currentSite && this.props.currentSite.recentActivity ? this.props.currentSite.recentActivity.map(act =>
                                            (<tr>
                                                <td>{act.month}</td>
                                                <td>{act.year}</td>
                                                <td>{act.visits}</td>
                                            </tr>
                                            )) : null}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {this.props.currentSite && this.props.allNoumenonNameList && this.props.currentSite.noumenonCodes && this.props.currentSite.noumenonCodes.length > 0 ?
                                <div className='recent-activity card mt-2 mb-2 react-bootstrap-table'>
                                    <legend>{labels.sites_labels.gpa_noumena}</legend>
                                    <div className='col-lg-12'>
                                        <table className='table table-striped table-hover table-condensed'>
                                            <thead className='recent-activity-header'>
                                                <tr>
                                                    <th>{labels.sites_labels.noumena}</th>
                                                    <th>{labels.sites_labels.start_date}</th>
                                                    <th>{labels.sites_labels.end_date}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {_.orderBy(this.props.currentSite.noumenonCodes).map(noumenon => {
                                                        const foundNoumenon = this.props.allNoumenonNameList.find(a => a.code === noumenon.code);
                                                        if (foundNoumenon) {
                                                        return(<tr>
                                                            <td>{foundNoumenon.noumenonName}</td>
                                                            <td>{ noumenon.startDate ? this.getStrongDate(noumenon.startDate) : 'Open'}</td>
                                                            <td>{ noumenon.endDate ? this.getStrongDate(noumenon.endDate): 'Open'}</td>
                                                        </tr>)
                                                        }
                                                        else {
                                                            null
                                                        }
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div> 
                            : ''}
                            {this.props.currentSite && this.props.currentSite.defaultPanels ?
                                <div className='row providers-users-sec react-bootstrap-table'>
                                    <div className='card card-default col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                        <legend>{labels.sites_labels.default_panels}</legend>
                                        <div className='card-body'>
                                            <ul>
                                                {_.orderBy(this.props.currentSite.defaultPanels.split(','), [panel => panel.toLowerCase()]).map(s => {
                                                    return <li>{s}</li>
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div> : ''}
                        </div>
                    </div>
                </>
        )
    }
}
export default SitesDetails