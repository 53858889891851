import React, { Component } from 'react';

import FormControl from '../../../components/FormControls/FormControl';
import labels from '../../../config/localization';
import userIcon from '../../../assets/images/user_avtar.png';
import constants from '../../../config/constants';
import PageTitle from '../../../components/PageTitle';
import { Typeahead } from 'react-bootstrap-typeahead';
import _ from 'lodash';
class CustomReferenceRangeForm extends Component {
  constructor(props, context) {
    super(props, context)
    this.panel = React.createRef()
    this.noumenonCode = React.createRef()
    this.units = React.createRef()
    this.scale = React.createRef()
    this.scaleList = [{ value: '0 Point'}, { value: '1 Point'}, { value: '2 Point'}, { value: '3 Point' }]
  }
  componentDidMount() {
      if (this.props.match.params && this.props.match.params.siteId && this.props.match.params.code) {
          this.props.setCurrentSiteDetails(this.props.match.params.siteId, 'custom_reference_range_form_edit', this.props.history)
          this.props.getNoumenonList(this.props.match.params.siteId, this.props.match.params.code, this.props.history, 'edit', true) 
          this.props.getPanels()
      }
      else {
        if (this.props.match.params && this.props.match.params.siteId) {
            this.props.setCurrentSiteDetails(this.props.match.params.siteId, 'custom_reference_range_form_new', this.props.history)
            this.props.getNoumenonList(this.props.match.params.siteId, null, this.props.history, 'new', true)
            this.props.getPanels()
          } 
      }
      this.noumenonInput = true
      this.panelInput = true
  }

  saveCustomNoumenon = () => {
    let noFormChanges = this.checkForChanges();
    if (!noFormChanges) {
      this.props.saveCustomNoumenon(this.props.match.params.siteId, this.props.history)
    }
  }

  checkForChanges = () => {
    let initialcurrentCustomNoumenon = this.props.initialcurrentCustomNoumenon ? { ...this.props.initialcurrentCustomNoumenon } : {};
    let currentCustomNoumenon = {}
    if (this.props.currentCustomNoumenon) {
        currentCustomNoumenon["panel"] = this.props.currentCustomNoumenon["panel"];
      currentCustomNoumenon["noumenonCode"] = this.props.currentCustomNoumenon["noumenonCode"];
      currentCustomNoumenon["noumenonName"] = this.props.currentCustomNoumenon["noumenonName"];
      currentCustomNoumenon["units"] = this.props.currentCustomNoumenon["units"];
      currentCustomNoumenon["scale"] = this.props.currentCustomNoumenon["scale"];
    }
    Object.keys(currentCustomNoumenon).forEach((p) => currentCustomNoumenon[p] == null || currentCustomNoumenon[p] == undefined || currentCustomNoumenon[p] == "" ? delete currentCustomNoumenon[p] : '');
    Object.keys(initialcurrentCustomNoumenon).forEach((p) => initialcurrentCustomNoumenon[p] == null || initialcurrentCustomNoumenon[p] == undefined || initialcurrentCustomNoumenon[p] == "" ? delete initialcurrentCustomNoumenon[p] : '');
    if (_.isEqual(currentCustomNoumenon, initialcurrentCustomNoumenon)) {
      this.props.showNoFormChangesError(true);
      return true;
    }
    else
      return false;

  }

 getFormatedRange = (row) =>  {
    let range
    range = (row.low != null && row.high != null) ? row.low + '-' + row.high :
        (row.low && !row.high) ? '>' + row.low :
            (row.high && !row.low) ? '<' + row.high :
                ''
    return range
 }

  render() {
    return (
        <> { !this.props.basicPlan ?
        <>
          {this.props.currentCustomReferenceRange && this.props.breadCrumbs?
            <>
              <div className='col-lg-3 col-md-12 col-sm-12 col-xs-12'></div>
              <form className='provider-form container col-lg-9' onSubmit={(e) => { e.preventDefault(); this.saveCustomNoumenon(this.props.match.params.siteId) }} >
                <div className='col-lg-12 row'>
                  <div className='col-lg-10 col-md-12 col-sm-12 col-xs-12'>
                    {this.props.customizationErrors && this.props.customizationErrors.length ?
                      <div className='alert alert-danger'>
                        {this.props.customizationErrors.map(re => <p>{re.error}</p>)}
                      </div> : null
                    }
                    <div className='form-group'>
                      <label>Enter Panel</label>
                      <Typeahead
                        required={true}
                        labelKey="name"
                        allowNew={false}
                        selected={this.props.currentCustomReferenceRange && this.props.currentCustomReferenceRange.panelName && this.panelInput ? [{ name: this.props.currentCustomReferenceRange.panelName }] : null}
                        onKeyDown={(e) => { e.length ? this.props.editCustomReferenceRangeForm(_.head(e), "panel") : null; this.panelInput = e.target.value ? false : true }}
                        id='panel'
                        options={this.props.panelList ? this.props.panelList : []}
                        renderMenuItemChildren={(option, props) => (
                          <span id={option.id} key={option.name}>{option.name}</span>
                        )}
                        onChange={(e) => this.props.editCustomReferenceRangeForm(_.head(e), "panel")}
                        // newSelectionPrefix={null}
                        ref={(ref) => this.panel = ref}
                        onBlur={(e) => {
                          this.panelInput = true;
                          if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                            this.props.editCustomReferenceRangeForm({ value: e.target.value, code: e.target.code }, "panel")
                          }
                        }
                        }
                        disabled={this.props.currentCustomReferenceRange && this.props.currentCustomReferenceRange.id ? true : false}
                      />
                    </div>
                    <div className='form-group'>
                      <label>Enter Noumenon</label>
                      <Typeahead
                        required={true}
                        labelKey="value"
                        allowNew={false}
                        selected={this.props.currentCustomReferenceRange && this.props.currentCustomReferenceRange.noumenonName && this.noumenonInput ? [{ value: this.props.currentCustomReferenceRange.noumenonName }] : null}
                        onKeyDown={(e) => { e.length ? this.props.editCustomReferenceRangeForm(_.head(e), "noumenonCode") : null; this.noumenonInput = e.target.value ? false : true }}
                        id='noumenonCode'
                        options={this.props.noumenonNameList ? this.props.noumenonNameList : []}
                        renderMenuItemChildren={(option, props) => (
                          <span id={option.code} key={option.code}>{option.value}</span>
                        )}
                        onChange={(e) => this.props.editCustomReferenceRangeForm(_.head(e), "noumenonCode")}
                        // newSelectionPrefix={null}
                        ref={(ref) => this.noumenonCode = ref}
                        onBlur={(e) => {
                          this.noumenonInput = true;
                          if (!e.target.parentElement.parentElement.contains(e.relatedTarget)) {
                            this.props.editCustomReferenceRangeForm({ value: e.target.value, code: e.target.code }, "noumenonCode")
                          }
                        }
                        }
                        disabled={this.props.currentCustomReferenceRange && this.props.currentCustomReferenceRange.id ? true : false}
                      />
                    </div>
                    <FormControl
                      value={this.props.currentCustomReferenceRange && this.props.currentCustomReferenceRange.units ? this.props.currentCustomReferenceRange.units : []}
                      type='select' id='units'
                      options={this.props.unitsList ? this.props.unitsList : []}
                      label={labels.custom_noumenon_list.units} onChange={this.props.editCustomReferenceRangeForm}
                      textKey={'value'} valueKey={'value'}
                      required={true} ref={this.units} />

                    <FormControl id='gender' type='select' label={labels.noumena_labels.gender}
                        options={constants.gender_lower} onChange={this.props.editCustomReferenceRangeForm}
                        textKey={'text'} valueKey={'value'}
                        value={this.props.currentCustomReferenceRange && this.props.currentCustomReferenceRange.gender
                            ? (this.props.currentCustomReferenceRange.gender) : null} required={true}
                        nodefaultOption={true}  />
                    <FormControl label={labels.custom_reference_ranges.referenceRange} 
                        onBlur={(e) =>  this.props.editCustomReferenceRangeForm(e, "referenceRange")} 
                        name='referenceRange'
                        placeholder='Enter Reference range' type='text'
                        id="range"
                        defaultValue={ this.props.currentCustomReferenceRange.range ? this.getFormatedRange(this.props.currentCustomReferenceRange.range) : "" } className='width103 form-control' tabindex="-1" />
                    <button className="btn btn-success">Save</button>

                  </div>
                </div>

              </form>
            </>
            : ""}
        </>
        : window.location.replace('/statuscode/404')
        }
        </>
      )
    }
}
export default CustomReferenceRangeForm