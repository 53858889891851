import React, { Component } from 'react';
import labels from '../../../config/localization';
import FormControl from '../../../components/FormControls/FormControl';
import constants from '../../../config/constants';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';

class CustomReferenceRanges extends Component {
    constructor(props) {
      super(props)
    }
    componentDidMount() {
      if (this.props.match.params && this.props.match.params.siteId) {
        this.props.getSitesList()
        this.props.setCurrentSiteDetails(this.props.match.params.siteId, 'customReferenceRanges', this.props.history)
      }
  }

    render() {
      const columns = [
        { dataField: 'panelName', headerStyle: { width: '25%' }, text: labels.custom_reference_ranges.panel_name_label, sort: true
        },
        { dataField: 'name', headerStyle: { width: '25%' }, text: labels.custom_reference_ranges.noumenon_name_label, sort: true,
            formatter: (cellContent, row) => (<Link className='patient-fullname' to={`/sites/${this.props.currentSite.id}/custom_reference_ranges/${row.code}`}>{row.name}</Link>)
        },
        // { dataField: 'code',headerStyle: { width: '30%' }, text: labels.custom_noumenon_list.noumenon_code_label, sort: true },
        { dataField: 'units',headerStyle: { width: '10%' }, text: labels.custom_noumenon_list.units, sort: true },
        { dataField: 'gender',headerStyle: { width: '10%' }, text: labels.custom_reference_ranges.gender, sort: true },
        { dataField: 'range',headerStyle: { width: '10%' }, text: labels.custom_reference_ranges.range, sort: true },

        { headerStyle: { width: '5%' },
          formatter: (cellContent, row) => (
                <FormControl className='user-edit-btn' type='button' btnType='default btn-small'
                    onClick={() => this.props.editCustomReferenceRange(this.props.history, `/sites/${this.props.currentSite.id}/custom_reference_ranges/${row.code}/edit`)} 
                    text={labels.user_labels.edit}>
                </FormControl>
            )
        },
        // { headerStyle: { width: '5%' },
        //   formatter: (cellContent, row) => (
        //         <FormControl className=' user-edit-btn' type='button' btnType='default btn-small'
        //             onClick={() => this.props.detailsCustomNoumenon(this.props.history, `/sites/${this.props.currentSite.id}/custom_optimal_ranges/${row.code}`)} 
        //             text={labels.user_labels.view}>
        //         </FormControl>
        //     )
        // },
        { headerStyle: { width: '5%' },
          formatter: (cellContent, row) => (
            <i className='fa fa-trash text-danger trash-icon-noumenon' onClick={() => this.props.toggleNoumenonModal('deleteNoumenon', row.code )}></i>
          )
        }
    ]
      return (
        <>
          { !this.props.basicPlan ?
          <>
          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 data-section p-0 users-children'>
            <div className='side-panel col-lg-3 col-md-12 col-sm-12 col-xs-12 order-2 users-list-sidepanel'>
                <div className='users-filter'>
                    <div className='new-user-btn-sec'>
                      <FormControl
                          id='siteId' label={labels.patient_list_labels.site_label}
                          type='select' 
                          onChange={(e) => this.props.setCurrentSiteDetails(e.target.value, 'customizations', this.props.history)}
                          // defaultOption={labels.patient_list_labels.all_sites}
                          nodefaultOption={true}
                          value={this.props.currentSite && this.props.currentSite.id ? parseInt(this.props.currentSite.id) : 0}
                          disabled={!this.props.sitesList || !this.props.sitesList.length}
                          options={this.props.sitesList && this.props.loggedInUser && this.props.loggedInUser.siteIds ? this.props.loggedInUser.role == 'admin' ? this.props.sitesList : 
                            this.props.sitesList.filter(site => this.props.loggedInUser.siteIds.includes(site.id) &&
                            (!site.planType || site.planType !== constants.planTypeBasic)) : []} 
                          textKey={'name'} valueKey={'id'} /> 
                        <FormControl type='button' btnType='default' text={labels.custom_reference_ranges.add_custom_reference_range} onClick={() => this.props.addCustomReferenceRanges(this.props.history)} />
                    </div>
                </div>

            </div>
            <div className='col-lg-9 col-md-12 col-sm-12 col-xs-12 p-0 customiz-table'>
                <div className='users-table custimization-table w-auto'>
                    {this.props.currentSiteCustomNoumenonList && this.props.currentSiteCustomNoumenonList.length ?
                        <div className='bootstrap-table-container'>
                            <BootstrapTable striped={false} bordered={false} hover={true} bootstrap4 keyField='id'
                                data={this.props.currentSiteCustomNoumenonList ? this.props.currentSiteCustomNoumenonList : []} columns={columns}
                            />
                        </div>
                    : <div className='jumbotron'>
                        <h3>{labels.no_grid_data}</h3>
                    </div>}
                </div>
            </div>
          </div>
          <div className={`modal fade ${this.props.isNoumenonModal ? 'show' : ''}`} role='dialog'>
              <div className='modal-dialog modal-dialog-centered modal-md'>
                  <div className='modal-content'>
                      <div className='modal-header'>
                          <h3>{labels.custom_fields_labels.noumeon_delete_warning}</h3>
                          <i className='fa fa-times fa-lg status-indicator text-danger' onClick={() => this.props.toggleNoumenonModal()}></i>
                      </div>
                      <div className='modal-body'>
                          <React.Fragment>
                              <div className="mb-4">{labels.custom_fields_labels.noumeon_delete_message}</div>
                              <div className='d-flex justify-space-between'>
                                  <FormControl type='button'
                                      btnType='default' className='btn-sm'
                                      text={labels.help_page_labels.cancel_btn} onClick={() => this.props.toggleNoumenonModal()} />
                                  <FormControl type='button'
                                      btnType='danger' className='btn-sm'
                                      text={labels.help_page_labels.ok_btn}
                                      onClick={() => this.props.deleteCustomNoumenon(this.props.history, this.props.currentSite.id)}
                                  />
                              </div>
                          </React.Fragment>
                      </div>

                  </div>
              </div>
          </div>
          </>
          : window.location.replace('/statuscode/404')
          }
        </>
      )
    }
}

export default CustomReferenceRanges;